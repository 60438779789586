import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const RPC_URLS = {
  5: "https://eth-goerli.g.alchemy.com/v2/PgmYkI8HyBNaehZXe6lV2lXdS88nXo8W",
  1: "https://rpc.ankr.com/eth",
};

export const injected = new InjectedConnector({
  // supportedChainIds: [4],
});

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  chainId: 5,
});

const walletlink = new WalletLinkConnector({
  url: RPC_URLS,
  appName: "passphrase",
  supportedChainIds: [1, 5],
});

export const desktopWalletList = [
  {
    name: "Metamask",
    connector: injected,
    connectorType: InjectedConnector,
  },
  {
    name: "WalletConnect",
    connector: walletconnect,
    connectorType: WalletConnectConnector,
  },
  {
    name: "WalletLink",
    connector: walletlink,
    connectorType: WalletLinkConnector,
  },
];

export const walletList = desktopWalletList;

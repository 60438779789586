import axios from "axios";
import { sign } from "../contracts/getContracts";
import { jwtManager } from "../helper/jwtManager";
import { isJsonString } from "../utils/helpers";

const register = (data) => {
  return axios.post("users", data);
};

const login = async (account) => {
  let info;
  const jsonStr = jwtManager.get();

  if (isJsonString(jsonStr)) {
    info = JSON.parse(jsonStr);
  }

  if (
    !info ||
    (info &&
      (info?.date !== ~~(Date.now() / (1000 * 60 * 60 * 24)) ||
        info?.auth?.length !== 175 ||
        info?.auth.split("-")[1].toLowerCase() !== account?.toLowerCase()))
  ) {
    const _sign = await sign(account);

    if (_sign) {
      const info = JSON.stringify({
        date: ~~(Date.now() / (1000 * 60 * 60 * 24)),
        auth: `${_sign}-${account}`,
      });

      jwtManager.set(info);
    }
  }

  // let _sign = jwtManager.get();

  // if (!_sign) {
  //   _sign = await sign(account);
  //   if (_sign === "") return;
  //   jwtManager.set(`${_sign}-${account}`);
  // }

  const response = await axios.get("auth");

  return response;
};

// const me = async () => {
//   return await axios.get("auth/user");
// };

// const updateProfile = async (payload) => {
//   if (payload?.new_password) payload.password = payload.new_password;
//   return await axios.post("/auth/profile", payload);
// };

// const forgot = async (email) => {
//   return await axios.post("/auth/reset_request", { email });
// };

// const getById = async (_id) => {
//   return (await axios.get("/auth/" + _id)).data;
// };

export default {
  // getById,
  // forgot,
  // register,
  login,
  // me,
  // updateProfile,
};

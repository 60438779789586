import React from "react";
import { useNavigate } from "react-router-dom";

const FooterSocial = ({ isShow }) => {
  if (!isShow) {
    return null;
  }

  return (
    <>
      <div className="social-footer-s" />
      <div className="social-f d-flex align-items-center pointer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <div className="fs-2 d-flex align-items-center pointer ">
            <div
              onClick={() => {
                window.open("https://twitter.com/passphrase_nft", "_blank");
              }}
            >
              <i className="fa-brands fa-twitter mx-3"></i>
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.instagram.com/passphrasenft/",
                  "_blank"
                );
              }}
            >
              <i className="fa-brands fa-instagram mx-3"></i>
            </div>
            <div
              onClick={() => {
                window.open("https://discord.com/invite/cJvBBGsT8G", "_blank");
              }}
            >
              <i className="fa-brands fa-discord mx-3"></i>
            </div>
            <img width="300px" alt="Store" src="/store.png" />
          </div>
          <div className="txt-footer">
            <i className="far fa-copyright me-2 grey-text"></i>All rights
            reserved
          </div>
        </div>
      </div>
    </>
  );
};
const Footer = ({ withSocial }) => {
  const navigate = useNavigate();
  return (
    <div className="footer-container">
      <div className="row">
        <div className="col">
          <h1 className="footer-text">
            Pass<span className="yellow-text">Phrase</span>
          </h1>
        </div>
      </div>
      <div className="row mt-42 mb-51">
        <div className="d-flex w-100 mobile-footer">
          <div className="col-1 footer-l">
            <div className="yellow-text footer-labels">
              <img
                width="22px"
                height="22px"
                src="/card-img/fh.png"
                alt=""
                className="img-footer-first"
              />
              <span
                className="ms-2 hover"
                onClick={() => navigate("/info/terms")}
              >
                Terms
              </span>
            </div>
          </div>
          <div className="col-1 footer-l">
            <div className="yellow-text footer-labels">
              <img
                width="16px"
                height="21px"
                src="/card-img/fl.png"
                alt=""
                className="img-footer"
              />
              <span
                className="ms-2 hover"
                onClick={() => navigate("/info/privacy")}
              >
                Privacy
              </span>
            </div>
          </div>
          {/* <div className="col-1 footer-l">
            <div className="yellow-text footer-labels">
              <img width="16px" height="20px" src="/card-img/fp.png" alt="" className="img-footer" />
              <span className="ms-2 hover"
                    onClick={() => navigate("/whitepaper")}>Whitepaper</span>
            </div>
          </div> */}
          {!withSocial ? (
            <div className="col float-end right-col txt-footer">
              <i className="far fa-copyright me-2 grey-text"></i>All rights
              reserved
            </div>
          ) : null}
        </div>
        <FooterSocial isShow={withSocial} />
      </div>
    </div>
  );
};

export default Footer;

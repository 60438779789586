import { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../style/road-map.css";
import { useWeb3React } from "@web3-react/core";

const MOBILE_BREAKPOINT = 541;

const generateHeadingImage = (phase) => () =>
  (
    <img
      src={`./img-step-${phase}-road-map.png`}
      alt=""
      className="img-step-1-road-map"
    />
  );

const CustomHeadingImageComponentPhase = () => {
  return (
    <>
      <img
        src="./small-coin-road-map.png"
        alt=""
        className="img-small-coin-road-map"
      />
      <img
        src="./large-coin-road-map.png"
        alt=""
        className="img-large-coin-road-map"
      />
      <img src="bg-step-2-road-map.png" alt="" className="bg-step-2-road-map" />
    </>
  );
};

const CONTENTS = [
  {
    phase: "1",
    title: "GAME DEVELOPMENT",
    body: `
      Anytime a new game segment opens up, it is inaugurated by a
      freshly minted NFT collection. To participate in the game, you
      simply acquire these NFTs. As soon as all of the NFTs are sold
      out, the game begins (don’t worry about missing out on it, we
      give all the players 24 hours to get ready).
    `,
    HeadingImageComponent: generateHeadingImage(1),
    lineConnection: "line-from-left-right-to-right",
    isComplete: true,
  },
  {
    phase: "2",
    title: "FOUNDERS KEYS SALE",
    body: `
      The next stage for us will be the distribution of the Founders
      Keys, which will offer holders a share of 10% of game profits.
      This is for every game played, which will be split between a
      very limited number of holders. Everyone can play and win, but
      not everybody can profit in the same way that Founders can.
    `,
    HeadingImageComponent: CustomHeadingImageComponentPhase,
    lineConnection: "line-from-right-left-to-left",
  },
  {
    phase: "3",
    title: "PUBLIC PROMOTION",
    body: `
      In order to begin a more mass adoption of our game and platform,
      we’ll look to give away three separate prize pools of 1, 10, and
      100 ETH. This prize pool will be offered only to players of our
      game. In doing so, we hope to bring more eyes to Passphrase, and
      therefore increased rewards for our holders.
    `,
    HeadingImageComponent: generateHeadingImage(3),
    lineConnection: "line-from-left-bottom-to-right",
  },
  {
    phase: "4",
    title: "DESIGN IOS AND ANDROID APPS",
    body: `
      Another thing that will help us to generate more attention will
      be making our platform more accessible. This starts with the
      design of an app that will allow users to play mobile. We’re
      looking to make the app attractive to all eyes, with a memorable
      style. In doing so, we’ll bridge the gap between web2 and web3
      users.
    `,
    HeadingImageComponent: generateHeadingImage(4),
    lineConnection: "line-from-right-bottom-to-left",
  },
  {
    phase: "5",
    title: "APP DEVELOPMENT",
    body: `
      With the design elements considered, we’ll also look to make the
      Passphrase application easy to use. With a simple and smooth
      experience, people will begin to trust the platform - much like
      other betting applications. Together, with our niche, holders
      will be able to bet ETH and play with ease.
    `,
    HeadingImageComponent: generateHeadingImage(5),
    lineConnection: "line-from-left-bottom-to-right",
  },
  {
    phase: "6",
    title: "APP LAUNCH",
    body: `
      Once our app has been tested, we’ll be ready to launch -
      delivering Passphrase to the masses, and to those that already
      enjoy it. When this happens, the potential perks of holding a
      Founders Key will be made apparent, as more people start to play
      the game, and prospective returns increase for each of the
      holders.
    `,
    HeadingImageComponent: generateHeadingImage(6),
    lineConnection: "line-from-right-bottom-to-left",
  },
  {
    phase: "7",
    title: "MARKETING PUSH",
    body: `
      With everything finally in place, and holders aware of the
      benefits of their Keys, we’ll do a final marketing push to
      introduce the game to those that haven’t yet played. This
      marketing push will end in a 1000 ETH giveaway, a life-changing
      amount of money.
    `,
    HeadingImageComponent: generateHeadingImage(7),
    lineConnection: "",
  },
];

const getCardBorder = (phase, isLeft, variant) => {
  let image = isLeft
    ? {
        header: "./border-left-step-road-map-v2.png",
        body: "./border-left-step-road-map-body-v2.png",
        footer: "./border-left-step-road-map-footer-v2.png",
      }
    : {
        header: "./border-right-step-road-map-v2.png",
        body: "./border-right-step-road-map-body-v2.png",
        footer: "./border-right-step-road-map-footer-v2.png",
      };

  if (phase === "1") {
    image = {
      header: "./border-first-step-road-map-v2.png",
      body: "./border-first-step-road-map-body-v2.png",
      footer: "./border-first-step-road-map-footer-v2.png",
    };
  }

  // mobile style handler
  if (variant === "sm") {
    image = {
      header: `./border-sm-${phase}-step-road-map-v2.png`,
      body: `./border-sm-${phase}-step-road-map-body-v2.png`,
      footer: `./border-sm-${phase}-step-road-map-footer-v2.png`,
    };
  }

  return image;
};

const calculateMaxHeight = (phase, height) => {
  const heightPadding = 36;
  if (phase === "2") {
    return `${height + (height <= 400 ? heightPadding : 66)}px`;
  }
  if (phase === "6") {
    return `${height + heightPadding}px`;
  }

  return "none";
};

const RoadMapCard = ({
  phase,
  title,
  body,
  isLeft,
  isComplete,
  HeadingImageComponent,
  lineConnection,
  variant,
}) => {
  const image = getCardBorder(phase, isLeft, variant);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(null);

  useEffect(() => {
    if (!contentHeight) {
      setContentHeight(contentRef.current?.offsetHeight);
    }
  }, [contentHeight]);

  return (
    <div
      className={`road-map-card ${
        isLeft || variant === "sm"
          ? "left align-self-start"
          : "right right align-self-end"
      } w-50 box-road-map v2 line-connection ${lineConnection} ${`phase-${phase}`}`}
    >
      <div className={isComplete ? "complete" : ""}>
        <img src={image.header} className={isLeft ? "w-100" : "w-100"} alt="" />
        <img
          src={
            isLeft ? "./step-left-road-map.png" : "./step-right-road-map.png"
          }
          className={`${
            isLeft
              ? "step-left-road-map step-left-3-road-map"
              : "step-right-road-map step-2-right-road-map-rm"
          } `}
          alt=""
        />
        <div
          className={`step-text ${
            isLeft ? "step-text-left" : "step-text-right step-text-right-rp"
          } v2`}
        >
          {phase}
        </div>
      </div>
      <div className="img-step-road-map">
        <div className="coin-road-map v2">
          <HeadingImageComponent />
        </div>
      </div>
      <div
        className={`${
          isLeft ? "text-step-road-map" : "text-step-right-road-map"
        } v2`}
        style={{
          backgroundImage: `url('${image.body}')`,
          maxHeight:
            variant === "sm"
              ? calculateMaxHeight(phase, contentHeight)
              : "none",
        }}
      >
        <div className="line-step-road-map"></div>
        <div className="text-phrase-step-road-map">{`PHASE ${phase}`}</div>
        <div className="text-title-step-road-map">{title}</div>
        <div ref={contentRef} className="text-desc-step-road-map">
          {body}
        </div>
      </div>
      <div className="footer-step-road-map">
        <img src={image.footer} alt="" />
      </div>
    </div>
  );
};

function RoadMap(props) {
  const { setAccount, setBalance } = props;
  const { account } = useWeb3React();
  const containerRef = useRef();
  const [clientWidth, setClientWidth] = useState(null);

  useEffect(() => {
    if (!clientWidth) {
      const X_PADDING = 20;
      setClientWidth(containerRef.current?.clientWidth + 2 * X_PADDING);
    }
  }, [clientWidth]);

  const renderRoadmapSteps = () => {
    return (
      <>
        <div className="align-self-end text-box-content-title v2">
          <p>
            But the fun doesn’t end there, because by this point Passphrase will
            be widely played, increasing the chances for you to earn your own
            money.
          </p>
          <img
            src="./bg-r-3-rm-rp.png"
            className="border-small-rm bg-r-3-rm-rp"
            alt=""
          />
        </div>
        {CONTENTS.map((content, idx) => {
          return (
            <RoadMapCard
              key={`phase${content.phase}`}
              phase={content.phase}
              title={content.title}
              body={content.body}
              HeadingImageComponent={content.HeadingImageComponent}
              isLeft={idx % 2 !== 1}
              lineConnection={content.lineConnection}
              isComplete={!!content.isComplete}
              variant={clientWidth <= MOBILE_BREAKPOINT ? "sm" : "lg"}
            />
          );
        })}
      </>
    );
  };

  return (
    <div ref={containerRef} className="container-road-map">
      <Header
        balance={0}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      <div className="d-flex flex-cow">
        <div className="box-title">
          <span className="home-main-text">
            FOUNDERS KEYS
            <br />
            <span className="yellow-text">ROADMAP</span>
          </span>
        </div>
      </div>
      <div
        className="box-txt-road-map row txt-road-map"
        style={{ display: "none" }}
      >
        <div className="txt-road-map-hidden">
          <div>
            The introduction of Founders Keys gives us a way to continuously
            reward those that believe in our game.
          </div>
        </div>
        <div className="col-6"></div>
      </div>
      <div className="d-fex mt-192">
        <div className="row container-key-road-map-rp">
          <div className="col-3 box-key-rp">
            <div className="txt-road-map box-txt-road-map">
              <div className="txt-road-map-rp">
                The introduction of Founders Keys gives us a way to continuously
                reward those that believe in our game.
              </div>
            </div>
          </div>
          <div className="col-3 box-key-rp">
            <div className="key-box key-box-road-map">
              <div className="key-text key-text-gold title-key-gold-road-map">
                GOLD
              </div>
              <div className="border-key border-key-road-map">
                <img src="/key-gold.png" className="key-gold-mint" alt="" />
                <img
                  src="/group-line.png"
                  alt=""
                  className="line-road-map-rp"
                />
                <div className="title-key-box">
                  <div className="row row-title-key">
                    <div className="col-6 text-title-key line-key">
                      <div className="txt-road-map">reward</div>
                      <div className="number-counter supply-key key-text-gold">
                        5<span className="x">x</span>
                      </div>
                    </div>
                    <div className="col-6 text-title-key ">
                      <div className="txt-road-map">supply</div>
                      <div className="number-counter supply-key key-text-gold">
                        750
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 box-key-rp box-key-silver-rp">
            <div className="key-box key-box-road-map">
              <div className="key-text key-text-silver title-key-silver-road-map">
                SILVER
              </div>
              <div className="border-key border-key-road-map">
                <img src="/key-silver.png" className="key-gold-mint" alt="" />
                <img
                  src="/group-line.png"
                  alt=""
                  className="line-road-map-rp"
                />
                <div className="title-key-box">
                  <div className="row row-title-key">
                    <div className="col-6 text-title-key line-key">
                      <div className="txt-road-map">reward</div>
                      <div className="number-counter supply-key key-text-silver">
                        2.3<span className="x">x</span>
                      </div>
                    </div>
                    <div className="col-6 text-title-key key-text-silver">
                      <div className="txt-road-map">supply</div>
                      <div className="number-counter supply-key">1250</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 box-key-rp">
            <div className="key-box key-box-road-map">
              <div className="key-text key-text-bronze title-key-bronze-road-map">
                BRONZE
              </div>
              <div className="border-key border-key-road-map">
                <img src="/key-bronze.png" className="key-bronze-mint" alt="" />
                <img
                  src="/group-line.png"
                  alt=""
                  className="line-road-map-rp"
                />
                <div className="title-key-box">
                  <div className="row row-title-key">
                    <div className="col-6 text-title-key line-key">
                      <div className="txt-road-map">reward</div>
                      <div className="number-counter supply-key key-text-bronze">
                        1<span className="x">x</span>
                      </div>
                    </div>
                    <div className="col-6 text-title-key">
                      <div className="txt-road-map">supply</div>
                      <div className="number-counter supply-key key-text-bronze">
                        2000
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex mt-192">
        <div className="row">
          <div className="col-12 container-content-title v2">
            <div className="box-content-title">
              WITHOUT FURTHER ADO, HERE ARE THE STEPS THAT WE'LL BE TAKING TO
              MAKE THIS HAPPEN:
            </div>
          </div>
        </div>
      </div>
      <div className={`container-step-road-map v2`}>
        <div
          className="d-flex flex-column container-step-road-map-rp"
          style={{ position: "relative" }}
        >
          {renderRoadmapSteps()}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RoadMap;

import { useMediaQuery, useTheme } from "@mui/material";

export function useBreakpoints() {
  const theme = useTheme();

  const _sm = useMediaQuery(theme.breakpoints.down("sm"));
  const _xs = useMediaQuery(theme.breakpoints.down("xs"));
  const _md = useMediaQuery(theme.breakpoints.down("md"));
  const _lg = useMediaQuery(theme.breakpoints.down("lg"));
  const _xl = useMediaQuery(theme.breakpoints.down("xl"));

  const sm_ = useMediaQuery(theme.breakpoints.up("sm"));
  const xs_ = useMediaQuery(theme.breakpoints.up("xs"));
  const md_ = useMediaQuery(theme.breakpoints.up("md"));
  const lg_ = useMediaQuery(theme.breakpoints.up("lg"));
  const xl_ = useMediaQuery(theme.breakpoints.up("xl"));

  return {
    _sm,
    _xs,
    _md,
    _lg,
    _xl,
    sm_,
    xs_,
    md_,
    lg_,
    xl_,
  };
}

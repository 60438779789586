import { useSubgraph } from "./useSubgraph";

export const useGame = () => {
  const { fetchIsGameStarted } = useSubgraph();

  async function getGameStatusInfo(gameId) {
    const [isGameStarted, timestamp] = await fetchIsGameStarted(gameId);

    if (!isGameStarted) return [false, false, 0, 0];

    // const gameCreatedTimestamp = (Number(timestamp) + 60 * 60 * 24) * 1000;
    const gameCreatedTimestamp = (Number(timestamp) + 60 * 3) * 1000;
    const current = Date.now();

    const diff = gameCreatedTimestamp - current;

    const canPlay = diff < 0;

    const _min = Math.abs(diff) / (1000 * 60);

    return [isGameStarted, canPlay, _min, Math.abs(diff)];
  }

  return {
    getGameStatusInfo,
  };
};

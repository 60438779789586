import React, { useEffect, useState } from 'react'
import PopupModal from '../components/Modal'
import { Button } from 'react-bootstrap'
import {
  SHARE_HASH_TAGS,
  SHARE_LINK,
  SHARE_MSG,
} from '../utilities/Constants/Constant'
import { copyToClipboard } from '../utilities/utils'
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

let iconStyles = { size: 40, round: true }

const ShareGame = ({ title, copiedText, onClose, msg, hashTags }) => {
  const [textCopied, setCopied] = useState(false)

  useEffect(() => {
    let copiedTextTimer = setTimeout(() => {
      setCopied(false)
    }, 500)
    return () => clearTimeout(copiedTextTimer)
  }, [textCopied])

  const copyText = () => {
    copyToClipboard(SHARE_MSG + ' ' + SHARE_LINK + '\n' + SHARE_HASH_TAGS)
    setCopied(true)
  }

  let body = (
    <>
      <Button
        variant={`outline-secondary`}
        onClick={copyText}
        className='w-100'
      >
        {textCopied ? 'Copied!' : 'Copy To Clipboard!'}
      </Button>
      <div className='d-flex justify-content-between mt-3'>
        <WhatsappShareButton title={msg} url={copiedText + '\n' + hashTags}>
          <WhatsappIcon {...iconStyles} />
        </WhatsappShareButton>
        <FacebookShareButton
          quote={msg + '\n' + hashTags}
          // hashtag={hashTags}
          url={copiedText}
        >
          <FacebookIcon {...iconStyles} />
        </FacebookShareButton>
        <TwitterShareButton
          title={msg}
          //hashtags={['#nft', '#nfts', '#playtowin', '#ethereum']}
          url={copiedText}
          //via={copiedText}
        >
          <TwitterIcon {...iconStyles} />
        </TwitterShareButton>
        <EmailShareButton
          subject={'Passphrase Live!'}
          url={''}
          body={`${msg} ${copiedText}\n  ${hashTags}`}
          separator={'\n'}
        >
          <EmailIcon {...iconStyles} />
        </EmailShareButton>
      </div>
    </>
  )
  return (
    <PopupModal title={title} onHide={onClose} body={body} classes='px-5' />
  )
}

ShareGame.defaultProps = {
  title: 'Share',
  copiedText: SHARE_LINK,
  msg: SHARE_MSG,
  hashTags: SHARE_HASH_TAGS,
}

export default ShareGame

import axios from "axios";
// import { cleanObject } from "helper/cleanObject";

const wordServiceV2 = {
  async list(gameId) {
    const result = await axios.get(`/words/${gameId}`);
    return result;
  },
  async randomList() {
    const result = await axios.get(`/words/random/?limit=50`);
    return result;
  },
  // async list(filter = {}) {
  //   let searchParams = new URLSearchParams(cleanObject(filter));
  //   return await axios.get("/categories?" + searchParams.toString());
  // },
  // async create(payload) {
  //   return await axios.post("/games", payload);
  // },
  // async remove(id) {
  //   return await axios.delete("/games/" + id);
  // },
  // async view(id) {
  //   return await axios.get("/games/" + id);
  // },
  // async update(id, payload) {
  //   return await axios.patch("/games/" + id, payload);
  // },
};

export default wordServiceV2;

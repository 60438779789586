export const CHAIN_ID = 1;

export const IS_IN_IFRAME = window.parent !== window;

export const RPC_URL = process.env.RPC_URL;

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const UINT256_MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const CONTRACT_ADDRESSES = {
  passPhrase: "0xa78f589b1209674c183a89233a8711fe2894a25e",
};

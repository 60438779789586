import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import ConnectWalletModal from "../components/connectWalletModal";
import gameService from "../services/gameService.js";
import { getEllipsisTxt } from "../helper/formatter";
import { Button } from "react-bootstrap";
import PrivateLayout from "../components/layouts/PrivateLayout";
import { toEther } from "../utils/helpers";
import { useSubgraph } from "../hooks/useSubgraph";
import { useWeb3React } from "@web3-react/core";
import { buyGame } from "../contracts/functions/passPhrase";
import playerServiceV2 from "../services/playerServiceV2";
import "../style/detail-page.css";

const DetailPage = (props) => {
  const { setAccount, setBalance } = props;

  const { fetchGameInfo } = useSubgraph();
  const [games, setGames] = useState({});
  const { state } = useLocation();
  const { game, info } = state || games;

  // console.log("chepi => ", game, info);

  const { account } = useWeb3React();
  const { fetchHasUserBought } = useSubgraph();

  const { gameId, tokenId } = useParams();
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [USD, setUSD] = useState();
  const [nft, setNft] = useState({});
  // const [game, setGame] = useState();
  const [loading, setLoading] = useState(false);
  const [hasBought, setHasBought] = useState(false);

  const [userBought, setUserBought] = useState(0);
  const navigate = useNavigate();
  const [numSold, setNumSold] = useState(0);
  const [extraLoading, setExtraLoading] = useState(false);
  const [extraLoadingTime, setExtraLoadingTime] = useState(0);

  const exchangeEthToUSD = async () => {
    const { data } = await gameService.exchangeEthToUSD();
    setUSD(data.USD);
  };
  // const getNftsSold = async () => {
  //   const { data } = await gameService.view(gameId);
  //   setNumSold(data?.result.sold);
  //   return data
  // }
  async function fetchGame() {
    exchangeEthToUSD();
    // const { data } = await gameService.view(gameId);

    // let numberPlayer = (data?.result.numberPlayer).toLocaleString("en-US");
    // setGame(data?.result);
    // setNumSold(data?.result.sold);
    // setNumberPlayer(numberPlayer);
    // if (tokenId) {
    //   const { data: player } = await playerService.getOne(tokenId, gameId);
    //   setNft(player?.result);
    // }

    setInterval(exchangeEthToUSD, 60000);
  }

  async function handlerHasUserBought() {
    if (account) {
      setHasBought(await fetchHasUserBought(game?.id ?? gameId, account));
    }
  }

  useEffect(() => {
    fetchGame();
    // async function init() {
    //   const pTx  = await localStorage.getItem('pendingTx');
    //   if(pTx && pTx !== 'null') {
    //     const {id, tx, ...rest}  = JSON.parse(pTx);
    //     const pendingTxGame = rest.game;
    //     if (pendingTxGame._id === gameId && id === nft?.tokenId) {
    //         Transaction.setTransaction(pendingTxGame, tx, id);
    //         checkTransactionStatus();
    //     } else {
    //       navigate(`/app/detail-page/${pendingTxGame._id}/${playerId}`);
    //     }
    //   }
    // };
    // init();
    handlerHasUserBought();

    // return () => componentMounted.current = false
  }, [gameId, account]);

  // async function checkTransactionStatus() {
  //   setExtraLoading(true);
  //   let timeout = 30;
  //   setExtraLoadingTime(timeout)
  //   const interval = setInterval(() => {
  //     if (timeout <= 0) {
  //       clearInterval(interval);
  //     }
  //     setExtraLoadingTime(prevState => prevState -1);
  //   }, 1000);
  //   setTimeout(async () => {
  //     try {
  //         const gameData = game;
  //         const numSoldData = numSold
  //         const nftData  = nfts;
  //         const tx = await getTransactionsDetails(Transaction.getTransaction());
  //         localStorage.removeItem('pendingTx');
  //         if (tx.status === true) {
  //           const location = window.location.href;
  //           const path = location.split('app')[0] + 'app/instructions/' + gameId;
  //           updateSoldNft(gameData, path, numSoldData, nftData.word, account);
  //           navigate(`/app/purchase-successful/${gameData._id}&${nftData.word}`);
  //         } else {
  //           toast.error('Transaction was failed.');
  //           setExtraLoading(false);
  //         }
  //     }
  //     catch(e) {
  //       console.log(e);
  //       // toast.error('Transaction is still not confirmed. Please wait.');
  //       // clearInterval(interval);
  //       // checkTransactionStatus();
  //     }
  //   }, )
  // }

  async function buyNFT() {
    if (account) {
      setLoading(true);
      await buyGame(game?.id, game?.gamePrice, account, () => {
        setUserBought(1);
        playerServiceV2.broadcast(game?.id);
        playerServiceV2
          .subscribeIfExist(game?.id)
          .then((res) => {
            setLoading(false);
            if (res.data?.status == "OK") {
              navigate(`/app/game`);
            } else {
              navigate(
                `/app/purchase-successful/${Number(game?.noOfUsers) + 1}`,
                {
                  state: {
                    game,
                  },
                }
              );
            }
          })
          .catch((e) => {
            console.log(e);
            navigate(
              `/app/purchase-successful/${Number(game?.noOfUsers) + 1}`,
              {
                state: {
                  game,
                },
              }
            );
          });
        // navigate(`/app/purchase-successful/${Number(game?.noOfUsers) + 1}`, {
        //   state: {
        //     game,
        //   },
        // });
      });
      setLoading(false);
    }

    // try {
    //   // if (!(await isValidChain())) {
    //   //   toast.error("Please connect to the correct network");
    //   //   return;
    //   // }
    //   // const nftSolds = await countMinted(game.tokenAddress);
    //   // setNumSold(nftSolds);
    //   // if (numberPlayer === nftSolds.toLocaleString("en-US")) {
    //   //   toast.warn("Nft has been sold out !");
    //   //   return;
    //   // } else {
    //   //   const location = window.location.href;
    //   //   const path = location.split("app")[0] + "app/instructions/" + gameId;
    //   //   const result = await buyToken(
    //   //     game.tokenAddress,
    //   //     game,
    //   //     path,
    //   //     account,
    //   //     game.sold + 1
    //   //   );
    //   //   const soldId = result.events.tokenSold.returnValues.tokenId;
    //   //   if (+soldId === +game.numberPlayer) {
    //   //     await gameService.update(gameId, {
    //   //       timeStart: Date.now() + 0 * 3600 * 1000,
    //   //     });
    //   //   }
    //   //   if (result.blockHash) {
    //   //     navigate(`/app/purchase-successful/${game._id}&${soldId}`);
    //   //   }
    //   // }
    // } catch (error) {
    //   toast.error(error.message);
    //   // if user reject metamask transaction
    //   if (error.code !== 4001) {
    //     // checkTransactionStatus();
    //   }
    // } finally {
    // }
  }

  async function gameInfo(account, gameId) {
    setLoading(true);
    const data = await fetchGameInfo(account, gameId);
    setGames({ game: data });
    setLoading(false);
  }

  useEffect(() => {
    if (!game && !info && gameId) {
      gameInfo(account ?? "", gameId);
    }
  }, [account, gameId]);

  // useEffect(()=>{
  //   console.log("Account Value::::::", account)
  // },[account])
  // console.log(gameId);
  function showClue() {
    if (hasBought) {
      if (info?.address === (account || "").toLowerCase()) {
        return (
          <p>
            Clue: <span style={{ color: "#ffd600" }}>{nft?.clue}</span>
            <br />
            You prepare to compete for a prize worth{" "}
            {Math.round(
              Number(toEther(game?.gamePrice)) *
                game?.totalPlayers *
                0.8 *
                10000
            ) / 10000}{" "}
            ETH.
          </p>
        );
      } else {
        return (
          <p>
            This NFT has owner. Please, purchase another NFT and prepare to
            compete for a prize worth{" "}
            {Math.round(
              Number(toEther(game?.gamePrice)) *
                game?.totalPlayers *
                0.8 *
                10000
            ) / 10000}{" "}
            ETH.
          </p>
        );
      }
    } else {
      return (
        <p>
          Participate for a chance to win {" "}
          {Math.round(
            Number(toEther(game?.gamePrice)) * game?.totalPlayers * 0.8 * 10000
          ) / 10000}{" "}
          ETH and enjoy captivating Alphas gameplay. As a Passphrase OG token holder, unlock exclusive access to Passphrase and the PassProtectors, elevating your experience.
        </p>
      );
    }
  }
  function showButtonPurchase() {
    if (hasBought || userBought > 0) {
      return <></>;
    }

    if (account) {
      return (
        <Button
          className={`main-button cursor-pointer purchase-button `}
          onClick={buyNFT}
          disabled={game?.noOfUsers === game?.totalPlayers}
        >
          {/* {loading ? 'Wait for process...' : 'Purchase'} */}
          {/* Mint */}
          Play
        </Button>
      );
    } else {
      return (
        <Button
          className={`mint-button cursor-pointer`}
          onClick={() => setShowConnectWallet(true)}
        >
          Connect wallet & Play
        </Button>
      );
    }
  }

  if (loading) {
    return (
      <div className="mt-131 sold-out-text wait-text">
        <div>
          Processing, Please wait.
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
          <br />
        </div>
        <div>You'll be redirected shortly</div>
      </div>
    );
  } else if (extraLoading) {
    return (
      <div className="mt-131 sold-out-text wait-text" style={{ width: "100%" }}>
        We are checking your transaction agian, Please wait.
        <div className="stage">
          <div className="dot-pulse"></div>
        </div>
        <br /> You'll be redirected in {extraLoadingTime}{" "}
      </div>
    );
  } else
    return (
      <PrivateLayout
        balance={0}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
        hasBack
      >
        <div className="detail-wrapper">
          <div className="detail-container">
            <div className="text-title">
              <span className="text-guess">GUESS THE PASSPHRASE & WIN</span>
              <br />
              <span className="text-money">
                ${" "}
                {new Intl.NumberFormat("en-US", {
                  maximumSignificantDigits: 3,
                }).format(
                  Math.round(
                    USD *
                      (Math.round(
                        Number(toEther(game?.gamePrice)) *
                          game?.totalPlayers *
                          0.8 *
                          10000
                      ) /
                        10000) *
                      1000
                  ) / 1000
                )}
              </span>
            </div>

            <div className="detail-row">
              <div className="detail-images">
                <div className="detail-images-wpapper">
                  <h1 className={"black-text"}>
                    {tokenId ? tokenId.toLocaleString("en-US") : "?"}
                  </h1>
                </div>
              </div>
              <div className="detail-card-text">
                <div className="text-mint">
                  <div className="detail-hd">Players Ready</div>
                  <div className="text-mint">
                    {Number(game?.noOfUsers) + userBought} /{" "}
                    {game?.totalPlayers}
                  </div>
                </div>
                <div className="price-text">
                  <div className="detail-hd">Price</div>
                  <div>
                    <i className="fab fa-ethereum"></i>{" "}
                    {toEther(game?.gamePrice)}
                  </div>
                </div>
                <div className="owner-text">
                  <div className="detail-hd">Owner</div>
                  <h3>
                    {tokenId ? getEllipsisTxt(info?.address) : "PASSPHRASE"}
                  </h3>
                </div>
                <div className="owner-text">
                  <div className="detail-hd">Story</div>
                  {showClue()}
                </div>
                <br />
                {game?.noOfUsers === game?.totalPlayers
                  ? null
                  : showButtonPurchase()}
                <br />
                {/* <Button
                  className={`main-button cursor-pointer purchase-button mt-2`}
                  onClick={() => navigate("/mint-your-key/" + gameId)}
                  disabled={numberPlayer === numSold.toLocaleString('en-US')}
                >
                  Join investor
                </Button> */}
              </div>
            </div>
          </div>
        </div>
        {showConnectWallet && !account && (
          <ConnectWalletModal
            show={showConnectWallet}
            onHide={() => setShowConnectWallet(false)}
            actions={{
              setAccount,
              setBalance,
            }}
          />
        )}
      </PrivateLayout>
    );
};

export default DetailPage;

import Header from "../Header";
import Footer from "../Footer";

const PrivateLayout = ({ balance, account, setAccount, setBalance, hasBack, user1, gameId, midComponents, children }) => {
  return (
    <div className="container-private">
      <Header
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
        isActiveNewMenu
        backArrow
      />
      {children}
      <Footer />
    </div>
  )
}

export default PrivateLayout

import Web3 from "web3";
import passPhraseABI from "./abis/passPhrase.json";
import { log } from "../utils/logs";
import { CONTRACT_ADDRESSES } from "../utils/constants";
import { toEther } from "../utils/helpers";

let web3;

export const setWeb3Provider = (provider) => {
  web3 = new Web3(provider);
};

export const passPhraseContract = () => {
  let contract;
  try {
    if (web3) {
      contract = new web3.eth.Contract(
        passPhraseABI,
        CONTRACT_ADDRESSES.passPhrase
      );
    }
    return contract;
  } catch (e) {
    log("contract", e);
  }
};

export const getBalance = async (account) => {
  try {
    if (web3) {
      return toEther(await web3.eth.getBalance(account));
    }
  } catch (e) {
    log("balance", e);
  }
  return "0";
};

export const getWeb3 = () => web3;

export const switchNetwork = async (
  chainInfo,
  account,
  switchToChainDECIMAL = "1"
) => {
  //

  try {
    // check if the chain to connect to is installed
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + parseInt(switchToChainDECIMAL).toString(16) }], // chainId must be in hexadecimal numbers
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await web3.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [chainInfo, account],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
  }

  if (web3) {
    try {
      await web3.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [chainInfo, account],
      });
    } catch (addError) {
      console.error(addError);
    }
  }
};

export const sign = async (account) => {
  try {
    if (web3 && account) {
      return await web3.eth.personal.sign(
        `${process.env.REACT_APP_AUTH}\n\nDay: ${~~(
          Date.now() /
          (1000 * 60 * 60 * 24)
        )}`,
        account
      );
    }
  } catch (e) {
    console.log(e);
  }
  return "";
};

import "../style/main.css";
import "@pqina/flip/dist/flip.min.css";

import { useEffect, useRef, useState } from "react";

import Tick from "@pqina/flip";
import { useNavigate } from "react-router-dom";

function getTimeRemaining(endtime){
  const minuteMillis = (1000*60)
  const hourMillis = (minuteMillis*60)
  const dayMillis = (hourMillis*24)

  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor( (total/1000) % 60 );
  const minutes = Math.floor( (total/minuteMillis) % 60 );
  const hours = Math.floor( (total/hourMillis) % 24 );
  const days = Math.floor( total/dayMillis );

  return {
    total,
    days: total > 0 && days > 0 ? days : 0,
    hours: total > 0 && hours > 0 ? hours : 0,
    minutes: total > 0 && minutes > 0 ? minutes : 0,
    seconds: total > 0 && seconds > 0 ? seconds : 0,
  };
}

function Counter(props) {
  // const { balance, setAccount, setBalance, account } = props;
  // 2023 March 31 02:00PM (PST)[22:00:00 UTC]
  // https://currentmillis.com/?1680300000000
  const millis = 1680300000000;
  const [time, setTime] = useState(getTimeRemaining(new Date(millis)));
  const [days, setDays] = useState(time.days);
  const [hours, setHours] = useState(time.hours);
  const [minutes, setMinutes] = useState(time.minutes);
  const [seconds, setSeconds] = useState(time.seconds);
  const navigate = useNavigate();
  const tickerInterval = useRef()

  useEffect(() => {
    if (time.total < 0 || (days <= 0 && minutes <= 0 && hours <= 0 && seconds <= 0)) {
      navigate('/mint');
    }
  }, [days, hours, minutes, seconds, time.total])

  useEffect(() => {
    tickerInterval.current = setInterval(() => {
      const remainingTime = getTimeRemaining(new Date(millis))
      setTime(remainingTime);
      setDays(remainingTime.days);
      setHours(remainingTime.hours);
      setMinutes(remainingTime.minutes);
      setSeconds(remainingTime.seconds);
    }, 1000)
    return () => tickerInterval.current && clearInterval(tickerInterval.current);
  }, []);

  return (
    <div className="counter-s">
      <img src="./left-counter.png" className="background-left-counter" alt=""/>
      <img src="./key-2-counter.png" className="background-key-2-counter" alt=""/>
      <img src="./key-5-counter.png" className="background-key-5-counter" alt="" />
      <img src="./right-counter.png" className="background-right-counter" alt="" />
      <img src="./key-4-counter.png" className="background-key-4-counter" alt="" />
      <img src="./coin-bg-mint.png" className="background-coin-counter" alt="" />
      <img src="./key-6-mint.png" className="background-key-6-counter" alt="" />
      <img src="./key-3-counter.png" className="background-key-3-counter" alt="" />
      <img src="./key-1-counter.png" className="background-key-1-counter" alt="" />
      <img src="./key-7-counter.png" className="background-key-7-counter" alt="" />
      <div className="counter-container">
        <div className="counter-box">
          <div className="counter-text counter-text-size">
            <span>
              <FlipDate value={[days]} />
            </span>
          </div>
          <div className="counter-text counter-text-size">
            <span>
              <FlipDate value={[hours]} />
            </span>
          </div>
          <div className="counter-text counter-text-size">
            <span>
              <FlipDate value={[minutes]} />
            </span>
          </div>
          <div className="counter-text counter-text-size">
            <span>
              <FlipDate value={[seconds]} />
            </span>
          </div>
        </div>
        <div className="counter-title-box">
          <div className="counter-title counter-text counter-title-size">
            DAYS
          </div>
          <div className="counter-title counter-text counter-title-size">
            HRS
          </div>
          <div className="counter-title counter-text counter-title-size">
            MINS
          </div>
          <div className="counter-title counter-text counter-title-size">
            SECS
          </div>
        </div>
      </div>
    </div>
  );
}

const FlipDate = ({ value }) => {
  const divRef = useRef();

  const tickRef = useRef();

  useEffect(() => {
    const didInit = (tick) => {
      tickRef.current = tick;
    };

    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit,
    });

    return () => Tick.DOM.destroy(tickValue);
  });

  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = value;
    }
  }, [value]);

  return (
    <div ref={divRef} className="tick">
      <div data-repeat="true">
        <span data-view="flip" />
      </div>
    </div>
  );
};

export default Counter;

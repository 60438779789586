import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NftCollectionCard from "../components/nftCollectionCard";
import GameLayout from "../components/layouts/GameLayout";
import "../style/nft-collection.css";
import gameService from "../services/gameService";
// import backArrow from "../assets/icons/backarrow.svg";
import playerService from "../services/playerService";
import { useWeb3React } from "@web3-react/core";
import { useSubgraph } from "../hooks/useSubgraph";
import { toEther } from "../utils/helpers";

const NftCollectionPlayer = (props) => {
  const { account } = useWeb3React();

  const { fetchUserNfts } = useSubgraph();

  const { setAccount, setBalance } = props;
  const params = useParams();
  const [nftsFiltered, setNftsFiltered] = useState([]);

  const [userNfts, setUserNfts] = useState([]);
  const [dataLimit, setDataLimit] = useState(16);
  const [game, setGame] = useState();
  const [loading, setLoading] = useState(true);
  // const [filter, setFilter] = useState(0);
  // const filterText = ['View all', 'Sold', 'UnSold'];
  // const [isOwner, setIsOwner] = useState(false);
  let navigate = useNavigate();

  async function handlerFetching() {
    // const { data } = await gameService.view(params.id);
    // setGame(data?.result);
    const nftList = await fetchUserNfts(account);
    setUserNfts(nftList);
    if (nftList.length <= 0 && !account) {
      navigate("/");
    }
    setNftsFiltered(nftList);
    setLoading(false);
  }

  useEffect(() => {
    if (account) {
      handlerFetching();
    }
  }, [params.id, account]);

  return (
    <GameLayout
      balance={0}
      account={account}
      setAccount={setAccount}
      setBalance={setBalance}
      gameId={params.id}
      loading={loading}
    >
      <div className="nft-card-wrapper">
        {loading ? (
          <div className="mt-131 sold-out-text wait-text">
            Loading
            <div className="stage">
              <div className="dot-pulse"></div>
            </div>
          </div>
        ) : userNfts.length > 0 ? (
          <div className="nft-card-row">
            {userNfts?.slice(0, dataLimit).map((info, key) => (
              <NftCollectionCard
                info={{
                  ...info,
                  isBought: true,
                }}
                key={key}
                game={info?.game}
                // walletaddress={account}
              />
            ))}
          </div>
        ) : (
          <>
            <div className="mt-131 sold-out-text">No NFTs purchased!</div>
            <div className="button-row">
              <div
                className="navbar-login cursor-pointer mt-40 cursor hover"
                onClick={() => navigate("/")}
              >
                Buy NFT
              </div>
            </div>
          </>
        )}
      </div>

      <div className="button-row">
        {nftsFiltered?.length > 16 && (
          <div
            className="navbar-login cursor-pointer"
            onClick={() => setDataLimit(20)}
          >
            View more
          </div>
        )}
      </div>
    </GameLayout>
  );
};

export default NftCollectionPlayer;

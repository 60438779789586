import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Web3 from "web3";
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { ConnectMetamaskContext } from "../contexts/connect-metamask";
import { useWeb3React } from "@web3-react/core";

export default function ConnectMetamaskProvider({ children }) {
  const [balance, setBalance] = useState(0);
  const [isCheckChainId, setIsCheckChainId] = useState(true);
  const { account: accounto, chainId: chainIdo } = useWeb3React();
  const url = process.env.RPC_URL;
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545", url);

  async function connectMetamask() {
    // try {
    //   if (window.ethereum) {
    //     try {
    //       await switchNetwork(process.env.REACT_APP_CHAIN);
    //       const address = await window.ethereum.enable();
    //       localStorage.setItem("account", address[0]);
    //       setAccount(address[0]);
    //       getBalance(address[0]);
    //     } catch (error) {
    //       console.log(error);
    //       toast.error("Please sign-in to MetaMask");
    //     }
    //   } else {
    //     toast.error(
    //       "You are not install Metamask! Please install metamask before use this function!"
    //     );
    //   }
    // } catch (error) {
    //   console.log(error);
    //   toast.error(error?.message);
    // }
  }

  const disconnectMetamask = async () => {
    setBalance("");
    localStorage.removeItem("account");
  };

  const getNetworkId = async () => {
    return await web3.eth.net.getId();
  };

  const getBalance = async (address = undefined) => {
    // if (address || account?.length > 0) {
    //   const balance = await web3.eth.getBalance(address ? address : account);
    //   const ethBalance = web3.utils.fromWei(balance, "ether");
    //   setBalance(ethBalance);
    //   setAccount(address ? address : account);
    // }
  };
  const switchNetwork = async (chainId) => {
    const current = await getNetworkId();
    if (accounto && +current !== chainId) {
      try {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
        getBalance();
      } catch (error) {
        console.log(error);
        if (error.code === 4902) {
          toast.error("Please add this chain id!");
        }
      }
    }
  };

  // useEffect(() => {
  //   const account = localStorage.getItem("account");
  //   if (account) {
  //     connectMetamask();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (account.length > 0) {
  //     if (window.ethereum) {
  //       function changeNetwork(networkId) {
  //         if (+networkId !== +process.env.REACT_APP_CHAIN && account.length > 0) {
  //           setIsCheckChainId(false);
  //         } else {
  //           setIsCheckChainId(true);
  //         }
  //       }
  //       window.ethereum.on('networkChanged', changeNetwork);
  //       return () => {
  //         window.ethereum.removeListener("networkChanged", changeNetwork);
  //       }
  //     } else {
  //       toast.error("You are not install Metamask! Please install metamask before use this function!");
  //     }
  //   }
  // }, [account])

  useEffect(() => {
    switchNetwork(process.env.REACT_APP_CHAIN);
  }, [accounto]);

  useEffect(() => {
    if (accounto && +chainIdo !== +process.env.REACT_APP_CHAIN) {
      setIsCheckChainId(false);
    } else {
      setIsCheckChainId(true);
    }
  }, [chainIdo, accounto]);

  // useEffect(() => {
  //   if (window.ethereum) {
  //     window.ethereum.on('accountsChanged', connectMetamask);
  //     return () => window.ethereum.removeListener("accountsChanged", connectMetamask);
  //   }
  // }, [])

  return (
    <>
      <Modal
        className="Connect-Modal"
        size="lg"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={!isCheckChainId}
      >
        <Modal.Body>
          <h4>Connect to wallet</h4>

          <div className="d-flex flex-column align-items-center justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span className="mt-2">Wrong network!</span>
            <span className="mt-2">Please change network!</span>
          </div>
        </Modal.Body>
      </Modal>
      <ConnectMetamaskContext.Provider
        value={{
          accounto,
          balance,
          connectMetamask,
          disconnectMetamask,
          getBalance,
        }}
      >
        {children}
      </ConnectMetamaskContext.Provider>
    </>
  );
}

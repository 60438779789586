import { createRef, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import gameService from "../../services/gameService.js";
import playerService from "../../services/playerService";
import wordServiceV2 from "../../services/wordServiceV2.js";
import "../../style/admin/nft-details.css";
import playerServiceV2 from "../../services/playerServiceV2.js";

function AdminNFTDetail() {
  const { id, playerId } = useParams();
  const { state } = useLocation();
  const { game, nft, total } = state;
  // console.log("state", state);
  let navigate = useNavigate();
  const imageRef = createRef();
  // const [words, setWords] = useState("");
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
  });
  // const [game, setGame] = useState();

  async function handlerUserInfo() {
    const { data } = await playerServiceV2.userInfo(game?.id, nft?.address);
    if (data && data?.status == "OK" && data?.user) {
      setUserInfo(data?.user);
    }
  }

  // async function fetchWords() {
  //   const { data } = await wordServiceV2.list(game?.id);
  //   setWords(data?.words?.join(" "));
  // }

  useEffect(() => {
    handlerUserInfo();
  }, [game, nft]);

  function showSold() {
    if (nft.isBought) {
      return (
        <>
          <tr className="table-nft-row">
            <td className="table-title-text">Username</td>
            <td className="table-value-text">{userInfo?.username}</td>
          </tr>
          <tr className="table-nft-row">
            <td className="table-title-text">Email</td>
            <td className="table-value-text">{userInfo?.email}</td>
          </tr>
          <tr className="table-nft-row">
            <td className="table-title-text">Wallet</td>
            <td className="table-value-text-small">{nft?.address}</td>
          </tr>
        </>
      );
    }
  }

  // async function downloadNft() {
  //   printToFile(imageRef.current, nft.word);
  // }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
        <div className="navbar-logo">
          <img
            onClick={() => {
              navigate(-1);
            }}
            src="/arrow.png"
            className="me-3 cursor-pointer"
            alt=""
          />
          {"Back"}
        </div>
        <div className="navbar-user">
          <span>DALLAS</span>
          <i className="fas fa-user-circle user-avatar"></i>
        </div>
      </div>

      <div className="nft-details-container d-flex flex-wrap">
        <div className="nft-title" ref={imageRef}>
          {nft?.word}
        </div>
        <div className="nft-details-text">
          <table>
            <tbody>
              <tr className="table-nft-row">
                <td className="table-title-text">Number</td>
                <td className="table-value-text">
                  {state.total} - Player {nft?.tokenId}
                </td>
              </tr>
              <tr className="table-nft-row">
                <td className="table-title-text">NFT</td>
                <td className="table-value-text">{nft?.tokenId}</td>
              </tr>

              {showSold()}
            </tbody>
          </table>
          {/* <div className="download-nft-button nft-button-m" onClick={downloadNft}>Download NFT</div> */}
        </div>
      </div>
    </>
  );
}

export default AdminNFTDetail;

import React from 'react';

// import Svg from "./Svg";

const Icon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_403_3657)">
        <path d="M22.7631 10.2311L17.8131 5.28113L16.4001 6.69913L20.7001 10.9991H6.61707V12.9991H20.7001L16.4001 17.2991L17.8141 18.7131L22.7641 13.7631C22.9963 13.531 23.1805 13.2553 23.3062 12.952C23.4318 12.6486 23.4965 12.3235 23.4965 11.9951C23.4965 11.6668 23.4318 11.3416 23.3062 11.0383C23.1805 10.7349 22.9963 10.4593 22.7641 10.2271L22.7631 10.2311Z"  fill={props.fill}/>
        <path d="M10.476 21C10.476 21.2652 10.3706 21.5196 10.1831 21.7071C9.99557 21.8946 9.74122 22 9.476 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H9.476C9.74122 2 9.99557 2.10536 10.1831 2.29289C10.3706 2.48043 10.476 2.73478 10.476 3V8.333H12.476V3C12.476 2.20435 12.1599 1.44129 11.5973 0.87868C11.0347 0.31607 10.2716 0 9.476 0L3 0C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3L0 21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H9.476C10.2716 24 11.0347 23.6839 11.5973 23.1213C12.1599 22.5587 12.476 21.7956 12.476 21V15.667H10.476V21Z" fill={props.fill} />
      </g>
      <defs>
        <clipPath id="clip0_403_3657">
        <rect width="24" height="24" fill={props.fill}/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;


const PRIZE_DATA = [
  {
    players: 100,
    totalEth: 100,
    priceEth: 0.1,
    prizePoolEth: 8.0,
  },
  {
    players: 500,
    totalEth: 500,
    priceEth: 0.1,
    prizePoolEth: 40.0,
  },
  {
    players: 1000,
    totalEth: 1000,
    priceEth: 0.1,
    prizePoolEth: 80.0,
  },
  {
    players: 10000,
    totalEth: 10000,
    priceEth: 0.1,
    prizePoolEth: 800.0,
  },
  {
    players: 20000,
    totalEth: 20000,
    priceEth: 0.1,
    prizePoolEth: 1600.0,
  },
  {
    players: 50000,
    totalEth: 50000,
    priceEth: 0.1,
    prizePoolEth: 4000.0,
  },
  {
    players: 100000,
    totalEth: 100000,
    priceEth: 0.1,
    prizePoolEth: 8000.0,
  },
  {
    players: 1000000,
    totalEth: 1000000,
    priceEth: 0.1,
    prizePoolEth: 80000.0,
  }
]

export default PRIZE_DATA

import React from "react";
import { useNavigate } from "react-router-dom";
import { toEther } from "../utils/helpers";

const NftCollectionCard = ({ info, game }) => {
  // console.log(info, game, walletaddress);
  const navigate = useNavigate();
  return (
    <>
      <div className="card-wrapper">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/app/detail-page/${game.id}/${info.nft}`, {
              state: {
                game,
                info,
              },
            });
          }}
        >
          <div className="card-item">
            <div className="card-images">
              <h2 className={info.isBought ? "red-text" : "yellow-text"}>
                {info.nft}
              </h2>
            </div>
            <div className="card-footer">
              <div className="card-footer-row">
                <div className="card-footer-tittel">{info.nft}</div>
                {info.isBought && <div className="red-text">SOLD OUT</div>}
              </div>
              <div className="card-footer-row">
                <div className="card-footer-tittel">Price</div>

                <div className="price yellow-text">
                  <i className="fab fa-ethereum"></i> {toEther(game?.gamePrice)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftCollectionCard;

import "../style/main.css";

function Mint(props) {
  // const { balance, setAccount, setBalance, account } = props;

  return (
    <div>
      <img src="./left-counter.png" className="background-left-counter" alt=""/>
      <img src="./key-2-counter.png" className="background-key-2-counter" alt=""/>
      <img src="./key-5-counter.png" className="background-key-5-counter" alt=""/>
      <img src="./right-counter.png" className="background-right-counter" alt=""/>
      <img src="./key-4-counter.png" className="background-key-4-counter" alt=""/>
      <img src="./coin-bg-mint.png" className="background-coin-counter" alt=""/>
      <img src="./key-6-mint.png" className="background-key-6-counter" alt=""/>
      <img src="./key-3-counter.png" className="background-key-3-counter" alt=""/>
      <img src="./key-1-counter.png" className="background-key-1-counter" alt=""/>
      <img src="./key-7-counter.png" className="background-key-7-counter" alt=""/>
      <div className="mint-container">
        <div className="mint-box-top">
          <div>MINT YOUR</div>
          <div className="yellow-text">PASSPHRASE KEY</div>
        </div>
        <div className="mint-box-bottom">
            <img src="./btn-mint.png" className="btn-mint" alt=""/>
        </div>
      </div>
    </div>
  );
}

export default Mint;


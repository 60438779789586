import axios from "axios";

const playerServiceV2 = {
  async guessPassphrase(gameId, words) {
    return await axios.post("/users", { gameId, words });
  },
  async broadcast(gameId) {
    return await axios.post("/users/broadcast", { gameId });
  },
  async winners(gameId) {
    return await axios.get(`/users/${gameId}`);
  },
  async claimReward(gameId) {
    return await axios.get(`/users/signature/${gameId}`);
  },

  async updateOrCreateUser(gameId, username, email) {
    return await axios.post(`/users/update`, {
      gameId,
      username,
      email,
    });
  },

  async subscribeIfExist(gameId) {
    return await axios.post(`/user-meta-data/subscribe`, {
      gameId,
    });
  },

  async getUserMetaData() {
    return await axios.get(`/user-meta-data`);
  },

  async updateUserMetaData(username, email) {
    return await axios.post(`/user-meta-data`, {
      username,
      email,
    });
  },

  async userInfo(gameId, account) {
    return await axios.post(`/users/user-info`, {
      gameId,
      account,
    });
  },

  // async updatePlayer(id, payload) {
  //   return (await axios.put("/players/" + id, payload)).data;
  // },
  // async generatorplayer(body) {
  //   return await axios.post(`/players/generatorplayers`, body);
  // },
  // async generatorplayertwice(body) {
  //   return await axios.post(`/players/generatorplayerstwice`, body);
  // },
  // async getOne(tokenId, gameId) {
  //   return axios.get(`/players/${tokenId}/${gameId}`);
  // },
  // async list(filter) {
  //   let searchParams = new URLSearchParams(filter);
  //   return await axios.get(`/players?${searchParams}`);
  // },
  // async listAll(filter) {
  //   let searchParams = new URLSearchParams(filter);
  //   return await axios.get(`/players/all/?${searchParams}`);
  // },
  // async update(body) {
  //   return await axios.post("/players", body);
  // },

  // async sendMail(body) {
  //   return await axios.post("/players/sendmail", body);
  // },

  // async listPlayerGame(game) {
  //   // let searchParams = new URLSearchParams(cleanObject(filter));
  //   const result = await axios.get(`/players/${game}`);
  //   return result;
  // },
  // async GetByGame(account) {
  //   // let searchParams = new URLSearchParams(cleanObject(filter));
  //   const result = await axios.get(`/players/getByGame/${account}`,);
  //   return result;
  // },
  // async listPlayerAccount(walletAddress) {
  //   // let searchParams = new URLSearchParams(cleanObject(filter));
  //   const result = await axios.get(`/players`, { params: { walletAddress } });
  //   return result;
  // },

  // async getClue(payload) {
  //   // let searchParams = new URLSearchParams(cleanObject(filter));
  //   const result = await axios.get(`/players`, { params: payload });
  //   return result;
  // },
  // async checkPlayer(payload) {
  //   // let searchParams = new URLSearchParams(cleanObject(filter));
  //   const result = await axios.get(`/players/check_player`, { params: payload });
  //   return result;
  // },
  // async generatorImage(body) {
  //   return await axios.post(`/players/generatorplayers`, body);
  // },
};

export default playerServiceV2;

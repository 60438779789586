import "./style/main.css";
import "./style/responsive.css";
import "react-toastify/dist/ReactToastify.css";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import AdminForgotPassword from "./pages/admin/forgot-password";
import AdminGameDetail from "./pages/admin/game-detail";
import AdminHome from "./pages/admin/home";
import AdminLiveGames from "./pages/admin/live-games";
import AdminLogin from "./pages/admin/login";
import AdminNFTDetail from "./pages/admin/nft-detail";
import ConnectMetamaskProvider from "./providers/connect-metamask";
import Counter from "./pages/counter";
import DetailPage from "./pages/detail-page";
import EndGame from "./pages/app/end-game";
import HowToPlay from "./pages/how-to-play";
import Instructions from "./pages/app/instructions";
import Mint from "./pages/mint";
import MintYourKey from "./pages/mint-your-key";
import NewGame from "./pages/admin/new-game";
import NftCollection from "./pages/nft-collection";
import NftCollectionPlayer from "./pages/nft-collection-player";
import Passphrase from "./pages/app/passphrase";
import PlayerGame from "./pages/player-games";
import Privacy from "./pages/privacy";
import PurchaseSuccessful from "./pages/purchase-successful";
import ReactGA from "react-ga4";
import Register from "./pages/register";
import RegisterDone from "./pages/register-done";
import RoadMap from "./pages/road-map";
import SubscribeEmail from "./pages/SubscribeEmail";
import Term from "./pages/term";
import { ToastContainer } from "react-toastify";
import WhitePaper from "./pages/whitePaper";
import Stats from "./pages/stats";
import { useEagerConnect } from "./hooks/walletConnect";
import authServiceV2 from "./services/authServiceV2";
import { useWeb3React } from "@web3-react/core";
import { sign } from "./contracts/getContracts";
import { jwtManager } from "./helper/jwtManager";
import { isJsonString } from "./utils/helpers";
import { Profile } from "./pages/profile";
import { LiveGames } from "./pages/LiveGames";

ReactGA.initialize(process.env.REACT_APP_GA);

function App() {
  useEagerConnect();

  const { account } = useWeb3React();

  const [balance, setBalance] = useState();
  const [login, setLogin] = useState(false);
  const location = useLocation();

  useEffect(async () => {
    if (account) {
      let info;
      const jsonStr = jwtManager.get();

      if (isJsonString(jsonStr)) {
        info = JSON.parse(jsonStr);
      }

      if (
        !info ||
        (info &&
          (info?.date !== ~~(Date.now() / (1000 * 60 * 60 * 24)) ||
            info?.auth?.length !== 175 ||
            info?.auth.split("-")[1].toLowerCase() !== account?.toLowerCase()))
      ) {
        const _sign = await sign(account);

        if (_sign) {
          const info = JSON.stringify({
            date: ~~(Date.now() / (1000 * 60 * 60 * 24)),
            auth: `${_sign}-${account}`,
          });

          jwtManager.set(info);
        }
      }
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      localStorage.setItem("account", account);
    }
  }, [account]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await authServiceV2.login(account);
      if (data.complete === false) {
        setLogin(false);
      } else {
        setLogin(true);
      }
    };

    if (location.pathname.includes("admin")) {
      if (localStorage.getItem("token")) {
        fetchUser();
      } else {
        setLogin(false);
      }
    }

    // ReactGA.send({
    //   hitType: "pageview",
    //   page: window.location.pathname + window.location.search,
    //   title: `user: ${account ?? "Wallet isn't connected"}`,
    // });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: `user: ${account ?? "Wallet isn't connected"}`,
    });
  }, [location, account]);

  return (
    <>
      <ToastContainer />
      <ConnectMetamaskProvider>
        <div
          className={
            // location.pathname === "/"
            //   ? "container-main-home"
            //   :
            //    `container-main ${location.pathname.replace("/", "")}`
            `container-main`
          }
        >
          <Routes>
            {/* <Route
              exact
              path="/"
              element={
                <Home
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            /> */}
            <Route
              path="/mint-your-key/:gameId"
              element={
                <MintYourKey
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/register-done" element={<RegisterDone />} />
            {/* <Route path="/admin/login" element={<AdminLogin />} /> */}
            <Route
              path="/admin/login"
              element={
                login ? <Navigate replace to="/admin/home" /> : <AdminLogin />
              }
            />
            <Route
              path="/admin/forgot-password"
              element={<AdminForgotPassword />}
            />
            <Route
              path="/admin"
              element={
                login ? <AdminHome /> : <Navigate replace to="/admin/login" />
              }
            />
            <Route
              path="/admin/home"
              element={
                login ? <AdminHome /> : <Navigate replace to="/admin/login" />
              }
            />
            <Route
              path="/admin/new-game"
              element={
                login ? <NewGame /> : <Navigate replace to="/admin/login" />
              }
            />
            <Route
              path="/admin/live-games"
              element={
                login ? (
                  <AdminLiveGames />
                ) : (
                  <Navigate replace to="/admin/login" />
                )
              }
            />
            <Route
              path="/admin/live-games/:id"
              // element={<AdminGameDetail /> }
              element={
                login ? (
                  <AdminGameDetail />
                ) : (
                  <Navigate replace to="/admin/login" />
                )
              }
            />
            <Route
              path="/admin/live-games/:id/:playerId"
              // element={<AdminNFTDetail />}
              element={
                login ? (
                  <AdminNFTDetail />
                ) : (
                  <Navigate replace to="/admin/login" />
                )
              }
            />
            <Route
              path="/app/passphrase/:gameId"
              element={<Passphrase account={account} />}
            />
            <Route
              path="/"
              element={
                <LiveGames
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            {/* <Route
              path="/app/live-collections"
              element={
                <NftCollectionMainpage
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            /> */}
            <Route
              path="/app/nft-collection-detail/:id"
              element={
                <NftCollection
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/nft-collection-detail-player/:id"
              element={
                <NftCollectionPlayer
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/game"
              element={
                <PlayerGame
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/detail-page/:gameId"
              element={
                <DetailPage
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/detail-page/:gameId/:tokenId"
              element={
                <DetailPage
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/purchase-successful/:id"
              element={
                <PurchaseSuccessful
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/profile"
              element={
                <Profile
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/purchase/success-subscribe-email/:gameId"
              element={
                <SubscribeEmail
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/instructions/:gameId"
              element={
                <Instructions
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/info/terms"
              element={
                <Term
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/info/privacy"
              element={
                <Privacy
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/counter"
              element={
                <Counter
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/mint"
              element={
                <Mint
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/how-to-play"
              element={
                <HowToPlay
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/whitepaper"
              element={
                <WhitePaper
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/road-map"
              element={
                <RoadMap
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/end-game/:id"
              element={
                <EndGame
                  balance={balance}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route
              path="/app/game/results/:gameId"
              element={
                <Stats
                  balance={"0"}
                  account={account}
                  setAccount={() => {}}
                  setBalance={setBalance}
                />
              }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </ConnectMetamaskProvider>
    </>
  );
}

export default App;

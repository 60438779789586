import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../style/nft-collection.css";
// import NftCollectionCard from '../components/nftCollectionCard'
// import { getNfts } from '../utilities/Web3/contract.js'
import gameService from "../services/gameService";
// import backArrow from '../assets/icons/backarrow.svg'
import Tab from "../components/Tab";
import GameLayout from "../components/layouts/GameLayout";
import PRIZE_DATA from "../constants/prize";
import { useWeb3React } from "@web3-react/core";
import gameServiceV2 from "../services/gameServiceV2";
import tableHead from "../assets/table-head.png";
import { trunc } from "../utils/helpers";
import { Tooltip } from "@mui/material";

const Stats = (props) => {
  const { setAccount, setBalance } = props;
  const { account } = useWeb3React();
  const { gameId } = useParams();
  // const [nftsFiltered, setNftsFiltered] = useState([])
  // const [soldIds, setSoldIds] = useState([])
  // const [nfts, setNfts] = useState([])
  // const [dataLimit, setDataLimit] = useState(16)
  // const [filter, setFilter] = useState(0)
  // const filterText = ['View all', 'Sold', 'UnSold']
  // const [isOwner, setIsOwner] = useState(false)
  const [stats, setStats] = useState({
    users: [],
    gamePrize: 0,
    noOfUsers: 0,
    startedAt: 0,
  });

  const [loading, setLoading] = useState(true);

  async function fetchGameStats() {
    setLoading(true);
    const { data } = await gameServiceV2.gameStats(gameId);
    if (data) {
      const sorted = (data?.stats?.users || []).sort((a, b) =>
        a.rank > b.rank ? 1 : -1
      );

      const lastIndex = sorted.findLastIndex((u) => u?.rank == 0);

      setStats({
        ...data?.stats,
        users: [
          ...sorted.slice(lastIndex + 1, sorted.length),
          ...sorted.slice(0, lastIndex + 1),
        ].sort((a, b) =>
          a.correctSeedsInOrder > b.correctSeedsInOrder ? -1 : 1
        ),
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchGameStats();
  }, [gameId]);

  return (
    <GameLayout
      gameId={"0"}
      balance={0}
      account={account}
      setAccount={setAccount}
      setBalance={setBalance}
    >
      <h1 className="game-card-title">
        <span className="game-text-big" style={{ color: "white" }}>
          Game <br />
        </span>
        <span
          className="game-text-big"
          style={{ marginTop: "1.5rem", display: "block" }}
        >
          results
        </span>
      </h1>

      <div style={{ marginTop: "8rem" }} />

      <div class="container" style={{ maxWidth: "100%" }}>
        <div class="row" style={{ alignItems: "center" }}>
          <div className="col-lg-8 cols-md-12">
            <div className="row box-table table-white-page-rp">
              <div className="tb-rp">
                <table className="table table-text tb-w">
                  <thead className="table-text-head table-head ">
                    <tr>
                      <th scope="col" className="th-bg th-bg-text-rp th-wp">
                        <img
                          src={tableHead}
                          className="bg-table-head-wp-rp bg-table-head-wp"
                          alt=""
                        />
                        Rank
                      </th>
                      <th scope="col" className="th-bg-text-rp th-wp">
                        Wallet address
                      </th>
                      <th scope="col" className="th-bg-text-rp th-wp">
                        Correct seeds in order
                      </th>
                      <th scope="col" className="th-bg-text-rp th-wp">
                        Correct seeds
                      </th>
                      <th scope="col" className="th-bg-text-rp th-wp">
                        Completed time
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-border-2"
                    style={{ overflow: "hidden" }}
                  >
                    {stats &&
                      stats?.users.map((user, index) => {
                        return (
                          <tr key={`players-${index}`}>
                            <td>
                              {Number(user?.correctSeedsInOrder) &&
                              Number(user?.correctSeeds) ? (
                                Number(user?.rank) ? (
                                  user?.rank
                                ) : (
                                  index + 1
                                )
                              ) : (
                                <Tooltip title="DO NOT COMPLETE" arrow>
                                  <div>DNC</div>
                                </Tooltip>
                              )}
                            </td>
                            <td>{trunc(user?.account)}</td>
                            <td>{user?.correctSeedsInOrder}</td>
                            <td>{user?.correctSeeds}</td>
                            <td>
                              {Math.abs(
                                (
                                  Number(user?.completedTime).toFixed(2) /
                                  (60 * 60 * 1000)
                                ).toFixed(2)
                              )}{" "}
                              Hrs
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 cols-md-12 stats-mt"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <div>
                <div>Players</div>
                <div
                  className="game-card-title"
                  style={{ fontSize: "1.6rem", color: "white" }}
                >
                  {stats?.noOfUsers}
                </div>
              </div>
              <div style={{ marginTop: "2rem" }}>
                <div>Prize pool</div>
                <div className="yellow-text" style={{ fontSize: "1.6rem" }}>
                  <i className="fab fa-ethereum"></i> {stats?.gamePrize}
                </div>
              </div>
              <div style={{ marginTop: "2rem" }}>
                <div>Date</div>
                <div
                  className="game-card-title"
                  style={{
                    fontSize: "1.6rem",
                    lineHeight: 1.2,
                    color: "white",
                  }}
                >
                  <div>
                    {new Date(stats?.startedAt).toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}{" "}
                    <br />
                    {new Date(stats?.startedAt).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GameLayout>
  );
};

export default Stats;

import * as React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Divider, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const emails = ["username@gmail.com", "user02@gmail.com"];

export function WordDialog({ open, onClose = () => {} }) {
  return (
    <>
      <IconButton
        onClick={onClose}
        style={{ position: "absolute", top: 10, right: 10, zIndex: 10000000 }}
      >
        <CancelIcon style={{ color: "white" }} />
      </IconButton>

      <Dialog
        style={{ backgroundColor: "#0E0F11" }}
        PaperProps={{
          style: {
            backgroundColor: "black",
            color: "white",
            width: "100vw",
            maxWidth: 250,
          },
        }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontFamily: "Minecraft",
            fontSize: "1.2rem",
          }}
        >
          WORD COLORS
        </DialogTitle>
        <Divider />
        <List>
          <ListItem
            disableGutters
            style={{
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "Minecraft",
                fontSize: "1.2rem",
                color: "#00FF85",
              }}
            >
              CORRECT WORD,
              <br /> CORRECT POSITION
            </Typography>
          </ListItem>
          <Divider />

          <ListItem
            disableGutters
            style={{
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "Minecraft",
                fontSize: "1.2rem",
                color: "#FF7324",
              }}
            >
              CORRECT WORD,
              <br /> INCORRECT POSITION
            </Typography>
          </ListItem>
          <Divider />

          <ListItem
            disableGutters
            style={{
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "Minecraft",
                fontSize: "1.2rem",
                color: "#FF1F1F",
              }}
            >
              INCORRECT WORD
            </Typography>
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}

// SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography>
      <br />
      <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button>
      <WordDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../style/nft-collection.css";
// import NftCollectionCard from '../components/nftCollectionCard'
// import { getNfts } from '../utilities/Web3/contract.js'
import gameService from "../services/gameService";
// import backArrow from '../assets/icons/backarrow.svg'
import Tab from "../components/Tab";
import GameLayout from "../components/layouts/GameLayout";
import { useWeb3React } from "@web3-react/core";
import { useSubgraph } from "../hooks/useSubgraph";
import gameServiceV2 from "../services/gameServiceV2";
import { useGame } from "../hooks/useGame";

const PlayerGame = (props) => {
  const { account } = useWeb3React();
  const { fetchUserNfts } = useSubgraph();
  const { getGameStatusInfo } = useGame();

  const { setAccount, setBalance } = props;

  // const params = useParams()
  // const [nftsFiltered, setNftsFiltered] = useState([])
  // const [soldIds, setSoldIds] = useState([])
  // const [nfts, setNfts] = useState([])
  // const [dataLimit, setDataLimit] = useState(16)
  const [games, setGames] = useState([]);

  // const [filter, setFilter] = useState(0)
  // const filterText = ['View all', 'Sold', 'UnSold']
  // const [isOwner, setIsOwner] = useState(false)
  const [past, setPast] = useState();
  const [gameStatus, setGameStatus] = useState({});
  const [liveGames, setLiveGames] = useState([]);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const [titles, setTitles] = useState({});

  async function fetchGames() {
    const nfts = await fetchUserNfts(account);
    const { data } = await gameServiceV2.finishedGames();
    if (data) setGameStatus(data?.games);
    setGames(nfts);
    setPast(false);
    setLoading(false);
  }

  const gameTitle = async (gameId) => {
    if (gameStatus[gameId]) return "The game has a winner";

    const [isStarted, canPlay, _min, diff] = await getGameStatusInfo(gameId);

    if (!isStarted) return `Game isn't started yet`;

    if (!canPlay) return `Starts in ${~~_min} Minutes`;

    if (canPlay) return `Game has been started`;
  };

  useEffect(() => {
    const arrGames = [...games];

    if (past) {
      setLiveGames(arrGames.filter((game) => gameStatus?.[game.gameId]));
    } else {
      setLiveGames(arrGames.filter((game) => !gameStatus?.[game.gameId]));
    }
    // setLiveGames(arrGames);
  }, [games, past, gameStatus]);

  useEffect(() => {
    if (account) {
      fetchGames();
    }
  }, [account]);

  useEffect(async () => {
    if (liveGames) {
      const titles = await Promise.all(
        liveGames.map((game) => gameTitle(game?.gameId))
      );
      const t = {};

      liveGames.forEach((game, idx) => {
        t[game?.gameId] = titles[idx];
      });
      setTitles(t);
    }
  }, [liveGames]);

  return (
    <GameLayout
      gameId={"game"}
      balance={0}
      account={account}
      setAccount={setAccount}
      setBalance={setBalance}
    >
      <div className="admin-live-nav-text">
        <span
          className="me-4 hover"
          onClick={() => {
            setPast(false);
          }}
        >
          Live games
        </span>
        <span
          className="hover"
          onClick={() => {
            setPast(true);
          }}
        >
          Past games
        </span>
      </div>
      <div className="admin-live-nav-bar">
        {!past ? (
          <div className="admin-bar-active"></div>
        ) : (
          <div className="admin-bar-active-past"></div>
        )}
      </div>
      {loading ? (
        <div className="mt-131 sold-out-text wait-text">
          Loading
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </div>
      ) : liveGames.length > 0 ? (
        <div className="game-container d-flex">
          {liveGames.map((val, index) => (
            <div className="game-card m-2">
              <div
                id={val._id}
                key={index}
                onClick={() => {
                  // if (!gameStatus[val?.gameId]) {
                  navigate(
                    gameStatus[val?.gameId]
                      ? `/app/game/results/${val.gameId}`
                      : `/app/instructions/${val.gameId}`,
                    {
                      state: {
                        user: val,
                      },
                    }
                  );
                  // }
                  // gameDeatils(val._id);
                }}
              >
                <div className="game-card-title">
                  {val?.game?.totalPlayers} <br />
                  players
                </div>
                <table className="mt-65">
                  <tbody>
                    {/* {val.status && (
                    <tr>
                      <td>
                        <img src="/game-4.png" alt="" />
                      </td>
                      <td className="game-table-text">No winner yet</td>
                    </tr>
                  )} */}
                    <tr>
                      <td>
                        <img src="/game-1.png" alt="" />
                      </td>
                      <td className="game-table-text">
                        {val?.game?.noOfUsers} NFTs sold
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src="/game-2.png" alt="" />
                      </td>
                      <td className="game-table-text ps-1">
                        {
                          titles && titles[val?.gameId]
                          // !gameStatus[val.gameId]
                          //   ? val?.game?.timestamp
                          //     ? `Started ${Math.floor(
                          //         (new Date().getTime() -
                          //           Number(val?.game?.timestamp) * 1000) /
                          //           1000 /
                          //           60
                          //       )} Minutes ago`
                          //     : "Not start"
                          //   : "The game has a winner"
                        }
                      </td>
                    </tr>
                    {/* <tr>
                  <td>
                    <img src="/game-3.png" alt="" />
                  </td>
                  <td className="game-table-text ps-1">{val.link}</td>
                </tr> */}
                  </tbody>
                </table>
              </div>
              {past && (
                <div
                  onClick={() => {
                    navigate(`/app/end-game/${val?.gameId}`);
                  }}
                >
                  <td className="game-table-text ps-1">
                    <Link>Collect reward of game no. {val?.gameId}</Link>
                  </td>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="mt-131 sold-out-text no-past-game">
            Please, purchase nft to play game!
          </div>
          <div className="button-row">
            <div
              className="navbar-login cursor-pointer mt-40 cursor hover"
              onClick={() => navigate("/")}
            >
              Buy NFT
            </div>
          </div>
        </>
      )}
    </GameLayout>
  );
};

export default PlayerGame;

import { log } from "../utils/logs";

const endpoint = "https://api.thegraph.com/subgraphs/name/billpass/passphrase";

const queryGamesByCategory = `
{
  lte1:games (
    first:1000,
    where:{ totalGameAmount_lte:1 }
  ){
    id
    users(orderBy:timestamp,orderDirection:asc){
      address
    }
    gamePrice
    timestamp
    noOfUsers
    totalPlayers
    totalGameAmount
    timestamp
    creator
  }
  gt1:games (
    first:1000,
    where:{ totalGameAmount_gt:1 }
  ){
    id
    users(orderBy:timestamp,orderDirection:asc){
      address
    }
    gamePrice
    timestamp
    noOfUsers
    totalPlayers
    totalGameAmount
    timestamp
    creator
  }
}`;

const queryGames = `
{
  games {
    id
    users(orderBy:timestamp,orderDirection:asc){
      address
    }
    gamePrice
    timestamp
    noOfUsers
    totalPlayers
    totalGameAmount
    timestamp
    creator
  }
}`;

const queryGameInfo = (gameId, account) => `
{
  game(id:${gameId}) {
    id
    users(where:{
      address:"${account}"
    }){
      address
      nft
      gameId
    }
    gamePrice
    noOfUsers
    totalPlayers
    totalGameAmount
    creator
    timestamp
  }
}`;

const queryUserNfts = (account = "") => `
{
  users(where:{
    address:"${account.toLowerCase()}"
  }){
    address
    gameId
    nft
    game{
      id
      noOfUsers
      totalPlayers
      totalGameAmount
      gamePrice
      timestamp
    }
  }
}`;

const queryGameCreator = (gameId) => `
{
  games(where:{
    id: ${gameId}
  }){
    creator
  }
}`;

const queryHasUserBought = (gameId, account) => `
{
	users(where:{
    address:"${account.toLowerCase()}",
    gameId: ${gameId}
  }){
    gameId
    address
  }
}`;

const queryIsGameStarted = (gameId) => `
{
  games(where:{
    id: ${gameId}
  }) {
    users(
      first:1,
      orderBy:timestamp,
      orderDirection:desc
    ){
      timestamp
    }
    noOfUsers
    totalPlayers
  }
}`;

const queryIsClaimed = (account = "", gameId) => `
{
  users(where:{address:"${account.toLowerCase()}", gameId:${gameId}}){
    isClaimed
  }
}`;

export const useSubgraph = () => {
  const fetchGamesByCategory = async () => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryGamesByCategory }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { gt1, lte1 } = data;
        return {
          gt1,
          lte1,
        };
      }
      return {
        gt1: [],
        lte1: [],
      };
    } catch (e) {
      log("fetchGamesByCategory", e);
      return {
        gt1: [],
        lte1: [],
      };
    }
  };

  const fetchGames = async () => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryGames }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { games } = data;
        return games;
      }
      return [];
    } catch (e) {
      log("fetchGames", e);
      return [];
    }
  };

  const fetchUserNfts = async (account) => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryUserNfts(account) }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { users } = data;
        return users;
      }
      return [];
    } catch (e) {
      log("fetchUserNfts", e);
      return [];
    }
  };

  const fetchGameCreator = async (gameId) => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryGameCreator(gameId) }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { games } = data;
        return games[0]?.creator;
      }
      return "0x";
    } catch (e) {
      log("fetchGameCreator", e);
      return "0x";
    }
  };

  const fetchHasUserBought = async (gameId, account) => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryHasUserBought(gameId, account) }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { users } = data;
        return users.length > 0;
      }
      return false;
    } catch (e) {
      log("fetchGameCreator", e);
      return false;
    }
  };

  const fetchIsGameStarted = async (gameId) => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryIsGameStarted(gameId) }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { games } = data;

        const users = games[0]?.users;

        const isStarted = games[0]?.noOfUsers === games[0]?.totalPlayers;

        const timestamp = users.length > 0 ? users[0]?.timestamp : 0;

        return [isStarted, Number(timestamp)];
      }
      return [false, 0];
    } catch (e) {
      log("fetchGameCreator", e);
      return [false, 0];
    }
  };
  const fetchIsUserClaimed = async (account, gameId) => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryIsClaimed(account, gameId) }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { users } = data;
        return users[0]?.isClaimed;
      }
      return false;
    } catch (e) {
      log("fetchGameCreator", e);
      return false;
    }
  };

  const fetchGameInfo = async (account, gameId) => {
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ query: queryGameInfo(gameId, account) }),
      });
      const resJSON = await res.json();
      const { data } = resJSON || {};
      if (data) {
        const { game } = data;
        return game || {};
      }
      return {};
    } catch (e) {
      log("fetchGameCreator", e);
      return {};
    }
  };

  return {
    fetchGames,
    fetchGamesByCategory,
    fetchUserNfts,
    fetchGameCreator,
    fetchHasUserBought,
    fetchIsGameStarted,
    fetchIsUserClaimed,
    fetchGameInfo,
  };
};

import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import BackArrow from "./BackArrow";
import ConnectWalletModal from "./connectWalletModal";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SignOutIcon from "../components/icons/SignOutIcon";
import { getBalance } from "../contracts/getContracts";
import { useWeb3React } from "@web3-react/core";
import playerServiceV2 from "../services/playerServiceV2";

const Header = (props) => {
  const navigate = useNavigate();

  const { account, deactivate } = useWeb3React();
  const { setAccount, backArrow, midComponents, user1 } = props;
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [isFetchingUser, setFetchingUser] = useState(false);
  const [user, setUser] = useState("");
  const [balance, setBalance] = useState(0);
  const [latestGameId, setLatestGameId] = useState("");
  const location = useLocation();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isActiveNewMenu, setIsActiveNewMenu] = useState(true);
  const [userMeta, setUserMeta] = useState({ username: "" });

  const isSignedIn = localStorage.getItem("account");
  // useEffect(() => {
  //   const checkNetwork = async () => {
  //     const validChainId = process.env.REACT_APP_CHAIN;
  //     try {
  //       const chainId = await web3.eth.net.getId();
  //       if (+chainId !== +validChainId) {
  //         toast.error("Please change network!");
  //       }
  //       // await window.ethereum?.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x' + validChainId }] });
  //     }
  //     catch (err) {
  //       console.log(err);
  //       // toast.error('First connect to wallet.', {
  //       //   position: "bottom-right",
  //       //   autoClose: 5000,
  //       //   hideProgressBar: false,
  //       //   closeOnClick: true,
  //       //   pauseOnHover: true,
  //       //   draggable: true,
  //       //   progress: undefined,
  //       // });
  //     }
  //   }
  //   checkNetwork();
  //   window.ethereum.on('networkChanged', function (networkId) {
  //     checkNetwork();
  //   });
  // }, [])

  // function metaMaskDetector() {
  //   window.ethereum?.on('accountsChanged', (accounts) => {
  //     if (!accounts.length) {
  //       // logic to handle what happens once MetaMask is locked
  //       setBalance(undefined);
  //       localStorage.removeItem('account');
  //       setReLoad(!reload)
  //     }
  //   });
  // }

  // useEffect(() => {

  //   metaMaskDetector()
  // }, [reload])

  // useEffect(() => {
  //   const getBalance = async () => {
  //     if (window.ethereum !== undefined) {
  //       try {
  //         const address = await window.ethereum?.enable();
  //         // setAccount(await address[0]);
  //         if (address.length > 0) {
  //           const balance = await web3.eth.getBalance(address[0]);
  //           const ethBalance = web3.utils.fromWei(balance, "ether");
  //           setBalance(ethBalance);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //         // toast.error('First connect to wallet.', {
  //         //   position: "bottom-right",
  //         //   autoClose: 5000,
  //         //   hideProgressBar: false,
  //         //   closeOnClick: true,
  //         //   pauseOnHover: true,
  //         //   draggable: true,
  //         //   progress: undefined,
  //         // });
  //       }
  //     } else {
  //       toast.error("You are not install metamask!");
  //     }
  //   }
  //   getBalance();

  // }, [])

  const [isSignOutHovered, setIsSignOutHovered] = useState(false);
  const handleSignOutMouseEnter = () => {
    if (!isSignOutHovered) {
      setIsSignOutHovered(true);
    }
  };
  const handleSignOutMouseLeave = () => {
    if (isSignOutHovered) {
      setIsSignOutHovered(false);
    }
  };

  // async function fetchPlayer() {
  //   try {
  //     setFetchingUser(true);
  //     const { data } = await playerService.listPlayerAccount(account);
  //     setFetchingUser(false);
  //     if (data) {
  //       console.log(data);
  //       if (data.PlayerByWallet.length > 0) {
  //         setLatestGameId(
  //           data.PlayerByWallet[data.PlayerByWallet.length - 1].game
  //         );
  //         setUsernameIfNotEmpty(data.PlayerByWallet);
  //       }
  //     } else {
  //     }
  //   } catch (error) {
  //     // console.log(error)
  //   }
  // }

  async function setUsernameIfNotEmpty(result) {
    for (let i = result.length - 1; i >= 0; i--) {
      if (result[i].userName !== "") {
        setUser(result[i].userName);
        break;
      }
    }
    return true;
  }

  // useEffect(() => {
  //   if (showUserName() && account.length > 0) {
  //     fetchPlayer();
  //   }
  // }, [account]);

  const handlerUserMetaData = async () => {
    if (account) {
      const res = await playerServiceV2.getUserMetaData();
      const data = res.data;

      if (data && data?.status == "OK") {
        setUserMeta(data?.user);
      }
    }
  };

  useEffect(async () => {
    if (account) {
      setBalance(await getBalance(account));
    }
  }, [account]);

  useEffect(() => {
    handlerUserMetaData();
    document.addEventListener("signature", handlerUserMetaData);

    return () => {
      document.removeEventListener("signature", handlerUserMetaData);
    };
  }, [account]);

  function showUserName() {
    if (
      location.pathname.includes("/") ||
      // location.pathname.includes("/app/live-collections") ||
      location.pathname.includes("/app/detail-page") ||
      location.pathname.includes("/app/purchase-successful") ||
      location.pathname.includes("/app/nft-collection-detail-player") ||
      location.pathname.includes("app/game") ||
      location.pathname.includes("/app/instructions") ||
      location.pathname.includes("/app/success-subscribe-email")
    ) {
      return true;
    }
    return false;
  }

  function fallbackTopBound() {
    const PUBLIC_ROUTES = [
      "road-map",
      "how-to-play",
      "counter",
      "whitepaper",
      "/",
      "counter",
      "mint-your-key/637375914b89e527e72ab6d0",
    ];
    return !PUBLIC_ROUTES.some((publicRoute) =>
      location.pathname.includes(publicRoute)
    );
  }

  function displayAccount() {
    if (account) {
      return (
        <div className={`txt-info-wallet ${isFetchingUser ? "loading" : ""}`}>
          {/* <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic"> */}
          {user !== "" || user1 !== "" ? (
            <div
              className="account-info username pointer"
              onClick={() => navigate(`/app/instructions/${latestGameId}`)}
            >
              {user ? user : user1}
            </div>
          ) : null}
          {balance >= 0 ? (
            <div className="account-info">
              {parseFloat(parseFloat(balance).toFixed(4))} ETH
            </div>
          ) : null}
          {/* </Dropdown.Toggle>
    </Dropdown> */}

          <div
            style={{
              borderLeft: "1px solid white",
              padding: "0px 10px",
              marginLeft: 5,
              fontWeight: 600,
              fontSize: "1rem",
            }}
          >
            <Link
              style={{
                color: "#f1c40f",
              }}
              to={"/app/profile"}
            >
              {userMeta?.username}
            </Link>
          </div>
          <div
            className="account-info pointer"
            onClick={() => {
              deactivate();
            }}
          >
            <SignOutIcon
              fill={isSignOutHovered ? "#FFD600" : "white"}
              onMouseEnter={handleSignOutMouseEnter}
              onMouseLeave={handleSignOutMouseLeave}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="btn-connect-wallet cursor-pointer"
          onClick={() => setShowConnectWallet(true)}
        >
          Connect wallet
        </div>
      );
    }
  }

  return (
    <div>
      <ToastContainer />
      <div
        className={`d-flex align-items-center justify-content-between navbar-main ${
          location.pathname === "/" ? "mx-4r" : ""
        }`}
      >
        <div className="navbar-logo">
          <div className="navbar-back">{backArrow && <BackArrow />}</div>
          <span onClick={() => navigate("/")}>
            PASS
            <span className="yellow-text">PHRASE</span>
          </span>
        </div>
        {/* NEW MENU */}
        {midComponents || (
          <div
            className={`
              ${isSignedIn ? "authenticated" : ""}
              ${
                (location.pathname === "/" || fallbackTopBound()) &&
                isActiveNewMenu
                  ? "new-menu new-menu-active"
                  : "new-menu"
              }
            `}
          >
            {/* <div
              className={`menu-item ${
                location.pathname?.includes("road-map") && isActiveNewMenu
                  ? "menu-item-active"
                  : ""
              }`}
              onMouseEnter={() => setIsActiveNewMenu(false)}
              onMouseLeave={() => setIsActiveNewMenu(true)}
              onClick={() => {
                navigate("/road-map");
              }}
            >
              Roadmap
            </div>
            <div
              className={`menu-item ${
                location.pathname?.includes("mint-your-key") && isActiveNewMenu
                  ? "menu-item-active"
                  : ""
              }`}
              onMouseEnter={() => setIsActiveNewMenu(false)}
              onMouseLeave={() => setIsActiveNewMenu(true)}
              onClick={() => navigate("/counter")}
            >
              Founder's key
            </div>
            <div
              className={`menu-item ${
                location.pathname?.includes("how-to-play") && isActiveNewMenu
                  ? "menu-item-active"
                  : ""
              }`}
              onMouseEnter={() => setIsActiveNewMenu(false)}
              onMouseLeave={() => setIsActiveNewMenu(true)}
              onClick={() => {
                navigate("/how-to-play");
              }}
            >
              How to Play
            </div> */}
            <div
              className={`menu-item ${
                location.pathname?.includes("nft-collection-mainpage") &&
                isActiveNewMenu
                  ? "menu-item-active"
                  : ""
              }`}
              onMouseEnter={() => setIsActiveNewMenu(false)}
              onMouseLeave={() => setIsActiveNewMenu(true)}
              onClick={() => {
                navigate("/");
              }}
            >
              Live Collections
            </div>
            <div
              className={`menu-item ${
                location.pathname?.includes("/app/game") && isActiveNewMenu
                  ? "menu-item-active"
                  : ""
              }`}
              onMouseEnter={() => setIsActiveNewMenu(false)}
              onMouseLeave={() => setIsActiveNewMenu(true)}
              onClick={() => {
                navigate("/app/game");
              }}
            >
              Dashboard
            </div>
            {/* <div
              className={`menu-item ${
                location.pathname?.includes("whitepaper") && isActiveNewMenu
                  ? "menu-item-active"
                  : ""
              }`}
              onMouseEnter={() => setIsActiveNewMenu(false)}
              onMouseLeave={() => setIsActiveNewMenu(true)}
              onClick={() => {
                navigate("/whitepaper");
              }}
            >
              White Paper
            </div> */}
          </div>
        )}

        <div className="btn-wallet">{displayAccount()}</div>

        {/* MENU MOBILE */}
        <div className="open-menu">
          <div
            className="btn-open-menu"
            onClick={() => {
              isOpenMenu ? setIsOpenMenu(false) : setIsOpenMenu(true);
            }}
          >
            {isOpenMenu ? <IconClose /> : <IconOpen />}
          </div>
          <div className={isOpenMenu ? "nav-tab nav-tab-open" : "nav-tab"}>
            <div className="menu-item-rp menu-item-first-rp">
              <div className="navbar-logo">
                <span onClick={() => navigate("/")}>
                  PASS
                  <span className="yellow-text">PHRASE</span>
                </span>
              </div>
            </div>
            {/* <div className="menu-item-rp" onClick={() => navigate("/road-map")}>
              <div
                className={`box-text-menu-rp ${
                  location.pathname?.includes("road-map") && isActiveNewMenu
                    ? "box-text-menu-active-rp"
                    : ""
                }`}
                onMouseEnter={() => setIsActiveNewMenu(false)}
                onMouseLeave={() => setIsActiveNewMenu(true)}
              >
                Roadmap
              </div>
            </div>
            <div className="menu-item-rp" onClick={() => navigate("/counter")}>
              <div
                className={`box-text-menu-rp ${
                  location.pathname?.includes("mint-your-key") &&
                  isActiveNewMenu
                    ? "box-text-menu-active-rp"
                    : ""
                }`}
                onMouseEnter={() => setIsActiveNewMenu(false)}
                onMouseLeave={() => setIsActiveNewMenu(true)}
              >
                Founder's key
              </div>
            </div>
            <div
              className="menu-item-rp"
              onClick={() => navigate("/how-to-play")}
            >
              <div
                className={`box-text-menu-rp ${
                  location.pathname?.includes("how-to-play") && isActiveNewMenu
                    ? "box-text-menu-active-rp"
                    : ""
                }`}
                onMouseEnter={() => setIsActiveNewMenu(false)}
                onMouseLeave={() => setIsActiveNewMenu(true)}
              >
                How to Play
              </div>
            </div> */}
            <div className="menu-item-rp" onClick={() => navigate("/")}>
              <div
                className={`box-text-menu-rp ${
                  location.pathname?.includes("nft-collection-mainpage") &&
                  isActiveNewMenu
                    ? "box-text-menu-active-rp"
                    : ""
                }`}
                onMouseEnter={() => setIsActiveNewMenu(false)}
                onMouseLeave={() => setIsActiveNewMenu(true)}
              >
                Live Collections
              </div>
            </div>
            <div className="menu-item-rp" onClick={() => navigate("/app/game")}>
              <div
                className={`box-text-menu-rp ${
                  location.pathname?.includes("/app/game") && isActiveNewMenu
                    ? "box-text-menu-active-rp"
                    : ""
                }`}
                onMouseEnter={() => setIsActiveNewMenu(false)}
                onMouseLeave={() => setIsActiveNewMenu(true)}
              >
                Dashboard
              </div>
            </div>
            {/* <div
              className="menu-item-rp"
              onClick={() => navigate("/whitepaper")}
            >
              <div
                className={`box-text-menu-rp ${
                  location.pathname?.includes("whitepaper") && isActiveNewMenu
                    ? "box-text-menu-active-rp"
                    : ""
                }`}
                onMouseEnter={() => setIsActiveNewMenu(false)}
                onMouseLeave={() => setIsActiveNewMenu(true)}
              >
                {" "}
                White Paper
              </div>
            </div> */}
            <div className=" menu-item-rp menu-item-last-rp">
              {displayAccount()}
            </div>
          </div>
        </div>
      </div>

      <div>
        {!account && (
          <ConnectWalletModal
            actions={{
              setAccount,
              setBalance,
            }}
            //  setAccount={setAccount}
            // setBalance={setBalance}
            show={showConnectWallet}
            onHide={() => setShowConnectWallet(false)}
          />
        )}
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return false;
}

const IconOpen = ({ width, height, className }) => {
  return (
    <svg
      width={width ?? "32"}
      height={height ?? "13"}
      viewBox="0 0 32 13"
      className={className ?? ""}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.503906"
        y="0.5"
        width="30.9964"
        height="1.99988"
        stroke="#FFD600"
      />
      <rect x="15.5" y="10.5" width="16" height="2" stroke="#FFD600" />
      <rect x="7.5" y="5.5" width="24" height="2" stroke="#FFD600" />
    </svg>
  );
};

const IconClose = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.707107"
        y="11.4971"
        width="15.2592"
        height="2"
        transform="rotate(-45 0.707107 11.4971)"
        stroke="#FFD600"
      />
      <rect
        x="2.12109"
        y="0.708083"
        width="15.2592"
        height="2"
        transform="rotate(45 2.12109 0.708083)"
        stroke="#FFD600"
      />
    </svg>
  );
};
export default React.memo(Header, areEqual);

import "../../style/app/instructions.css";
import "../../style/responsive.css";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "react-bootstrap";

import PrivateLayout from "../../components/layouts/PrivateLayout";
import { useWeb3React } from "@web3-react/core";
import playerServiceV2 from "../../services/playerServiceV2";
import {
  claimReward,
  gameDetails,
  gameOwner,
  withdrawCreatorEarning,
  withdrawTreasuryEarning,
} from "../../contracts/functions/passPhrase";
import { useSubgraph } from "../../hooks/useSubgraph";

export default function EndGame({ balance, setAccount, setBalance }) {
  const { account } = useWeb3React();
  const navigate = useNavigate();
  const { fetchGameCreator, fetchIsUserClaimed } = useSubgraph();
  const { id: gameId } = useParams();

  const [claimedInfo, setClaimedInfo] = useState({
    isUserClaimed: false,
    isAdminClaimed: false,
    isCreatorClaimed: false,
  });

  const [owner, setOwner] = useState("");
  const [creator, setCreator] = useState("");
  const [treasuryAddress, setTreasuryAddress] = useState("");
  const [isTreasuryAddressSet, setIsTreasuryAddressSet] = useState(false);

  const [ranking, setRanking] = useState([]);

  const withdrawForOwner = async () => {
    if (account && gameId) {
      if (treasuryAddress === "") {
        return setIsTreasuryAddressSet(true);
      }
      setIsTreasuryAddressSet(false);
      withdrawTreasuryEarning(treasuryAddress, gameId, account, () => {
        handlerClaimedInfo();
      });
    }
  };
  const withdrawForInvestor = async () => {
    if (account && gameId) {
      withdrawCreatorEarning(gameId, account, () => {
        handlerClaimedInfo();
      });
    }
  };

  // const fetchInvestor = async () => {
  //   try {
  //     const investor = await investorService.getByGameAndAddress(id, account);
  //     if (investor) {
  //       setIsInvestor(true);
  //     } else {
  //       setIsInvestor(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchGame = async (id) => {
  //   try {
  //     const { data } = await gameService.view(id);
  //     if (data.result.status && (await isEndGame(data.result.tokenAddress))) {
  //       data.result.status = false;
  //       await gameService.update(data.result._id, { status: false });
  //     }
  //     setGame(data.result);
  //     fetchOwner(data.result.user);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const claimWinings = async (user) => {
    if (account && user) {
      const { data } = await playerServiceV2.claimReward(gameId);

      if (data && data?.status === "OK") {
        await claimReward(
          gameId,
          user.ranking,
          user.prize,
          data?.user?.signature,
          account,
          () => {
            handlerClaimedInfo();
          }
        );
      }
    }
  };

  const fetchOwner = async () => {
    if (account) {
      const owner = await gameOwner();
      setOwner(owner.toLowerCase());
    }
  };

  const fetchCreator = async () => {
    if (account) {
      const creator = await fetchGameCreator(gameId);
      setCreator(creator);
    }
  };

  const fetchRanking = async () => {
    try {
      const { data } = await playerServiceV2.winners(gameId);
      if (data) {
        const sorted = (data?.users || []).sort((a, b) =>
          a.ranking > b.ranking ? 1 : -1
        );

        const lastIndex = sorted.findLastIndex((u) => u?.ranking == 0);
        setRanking([
          ...sorted.slice(lastIndex + 1, sorted.length),
          ...sorted.slice(0, lastIndex + 1),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlerClaimedInfo = async () => {
    if (account && gameId) {
      const gameInfo = await gameDetails(gameId);
      const hasUserClaimed = await fetchIsUserClaimed(account, gameId);
      setClaimedInfo({
        isAdminClaimed: gameInfo?.isAdminClaimed,
        isCreatorClaimed: gameInfo?.isCreatorClaimed,
        isUserClaimed: hasUserClaimed,
      });
    }
  };

  useEffect(() => {
    fetchRanking();
    fetchOwner();
    fetchCreator();
  }, [account]);

  useEffect(() => {
    handlerClaimedInfo();
  }, [account, gameId]);

  return (
    <PrivateLayout
      balance={balance}
      account={account}
      setAccount={setAccount}
      setBalance={setBalance}
    >
      <div className="min-content-height">
        {[owner, creator].includes((account || "").toLowerCase()) && (
          <div className="d-flex justify-content-end">
            {account && owner == account.toLowerCase() && (
              <>
                {isTreasuryAddressSet && (
                  <input
                    placeholder="Please paste here your treasury address"
                    type={"text"}
                    style={{ width: "100%", borderRadius: 10 }}
                    onChange={(e) => {
                      setTreasuryAddress(e.target.value);
                    }}
                  />
                )}
                <Button
                  disabled={claimedInfo?.isAdminClaimed}
                  className={`mint-button d-flex justify-content-center cursor-pointer w-unset me-2`}
                  onClick={() => withdrawForOwner()}
                >
                  Withdraw of Owner
                </Button>
              </>
            )}
            {account && creator == account.toLowerCase() && (
              <Button
                disabled={claimedInfo?.isCreatorClaimed}
                className={`mint-button d-flex justify-content-center cursor-pointer w-unset`}
                onClick={() => withdrawForInvestor()}
              >
                Withdraw of Investor
              </Button>
            )}
          </div>
        )}
        <div className="row box-table table-white-page mt-0">
          <div className="col">
            <div className="tb-rp">
              <table className="table table-text table-bordered">
                <thead
                  style={{ color: "white" }}
                  className="table-text-head table-head "
                >
                  <tr>
                    <th scope="col">Ranking</th>
                    <th scope="col">Address</th>
                    <th style={{ whiteSpace: "nowrap" }} scope="col">
                      Player Id
                    </th>
                    <th style={{ whiteSpace: "nowrap" }} scope="col">
                      Prize Pool ETH
                    </th>
                    <th style={{ whiteSpace: "nowrap" }} scope="col">
                      Prize Pool USD
                    </th>
                    <th style={{ whiteSpace: "nowrap" }} scope="col"></th>
                  </tr>
                </thead>
                <tbody className="table-border">
                  {ranking?.map((rank, idx) => {
                    return (
                      <tr key={rank.address}>
                        <td>{Number(rank.ranking) ? rank.ranking : idx + 1}</td>
                        <td>{rank.account}</td>
                        <td>{rank.tokenId}</td>
                        <td>
                          {Number(rank.prize) <= 0
                            ? "_"
                            : `${Number(rank.prize)} ETH`}
                        </td>
                        <td>
                          {Number(rank.prize) <= 0
                            ? "_"
                            : `$ ${(Number(rank.prize) * 1306.35).toFixed(4)}`}
                        </td>
                        <td>
                          {(account || "").toLowerCase() ===
                            rank.account.toLowerCase() && (
                            <Button
                              disabled={
                                claimedInfo?.isUserClaimed ||
                                Number(rank.prize) <= 0
                              }
                              className="mint-button  cursor-pointer w-unset"
                              onClick={() => claimWinings(rank)}
                            >
                              Withdraw
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PrivateLayout>
  );
}

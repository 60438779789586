import { Pagination, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

const Carousal = ({
  arts = [],
  straight = false,
  direction,
  btns,
  container_className = "",
}) => {
  // effect: "coverflow",
  // grabCursor: true,
  // centeredSlides: true,
  // // slidesPerView: "fit",
  // spaceBetween: 5,
  // loop: true,
  // // autoplay: {
  // //   delay: 2000,
  // //   disableOnInteraction: false,
  // // },
  // coverflowEffect: {
  //   rotate: 10,
  //   stretch: 10,
  //   depth: 150,
  //   modifier: 1,
  //   slideShadows: false,
  // },
  // console.log("crousal =>. ", arts.length);
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      // allowSlideNext
      // allowSlidePrev
      // allowTouchMove
      // observer
      // observeParents
      // parallax
      navigation
      //       loopFillGroupWithBlank: true,
      // centeredSlides: true,
      // slidesPerView={"auto"}
      loopFillGroupWithBlank={true}
      centeredSlidesBounds={true}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        500: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
        1300: {
          slidesPerView: 4,
        },
        1800: {
          slidesPerView: 5,
        },
      }}
      loop={false}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        // rotate: 10,
        // stretch: 10,
        // depth: 150,
        // modifier: 1,
        slideShadows: false,
      }}
      pagination={false}
      modules={[Pagination, EffectCoverflow, Navigation]}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {arts.map((art, idx) => (
        <SwiperSlide key={idx}>{art}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousal;

import { useEffect, useState } from "react";
import "../../style/admin/game-details.css";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import gameService from "../../services/gameService.js";
import Account from "../../components/Account";
import playerService from "../../services/playerService";
import ReactPaginate from "react-paginate";
import { toEther } from "../../utils/helpers";

function AdminGameDetail() {
  const { id } = useParams();
  const { state } = useLocation();
  const { game } = state;
  // const [game, setGame] = useState();
  const [nfts, setNfts] = useState([]);
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);

  const nftPerPage = 40;
  const pagesVisited = pageNumber * nftPerPage;
  const pageCount = Math.ceil(nfts.length / nftPerPage);

  const changePage = ({ selected }) => {
    setLoading(true);
    setPageNumber(selected);
    setLoading(false);
  };

  async function prepareNfts() {
    setLoading(true);

    const list = [];

    for (let i = 0; i < parseInt(game?.totalPlayers); i++) {
      const isTrue = i < parseInt(game?.noOfUsers);
      list.push({
        isBought: i < parseInt(game?.noOfUsers),
        tokenId: i + 1,
        address: isTrue ? game?.users[i]?.address : "0x",
      });
    }

    // const { data } = await gameService.view(id);
    // setGame(data?.result);
    // const { data: nftList } = await playerService.listAll({ game: id });
    setNfts(list);
    setLoading(false);
  }
  // async function generatorImage() {

  //   const { data } = await gameService.getMetaData(id, 1);
  //   console.log(data.result)

  // }

  // async function generatorImageTest() {
  //   const { data } = await gameService.getImage(id, 1);
  //   console.log(data.result)

  // }

  useEffect(() => {
    prepareNfts();
  }, [id]);

  const goBack = () => {
    navigate(`/admin/live-games`);
  };

  if (loading) {
    return <div className="mt-131 sold-out-text">Loading....</div>;
  }

  const showNfts = (arr) => {
    let nftarr = [];
    let arrFilter = [];
    if (tab === 1) {
      arrFilter = arr?.filter((p) => p.isBought !== true);
    }
    if (tab === 2) {
      arrFilter = arr?.filter((p) => p.isBought === true);
    }
    if (arrFilter.length > 0) {
      arrFilter
        ?.slice(pagesVisited, pagesVisited + nftPerPage)
        .map((nft, i) => {
          nftarr.push(
            <NftCard game={game} nft={nft} key={i} total={arrFilter.length} />
          );
          return nftarr;
        });
    } else {
      nftarr = [];
    }
    // eslint-disable-next-line array-callback-return

    return nftarr;
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
        <div className="navbar-logo">
          <img
            onClick={goBack}
            src="/arrow.png"
            className="me-3 cursor-pointer"
            alt=""
          />
          {nfts?.players} PLAYERS
        </div>
        {/* <div className="navbar-user">
          <span>DALLAS</span>
          <i className="fas fa-user-circle user-avatar"></i>
        </div> */}
        <Account />
      </div>

      <div className="admin-live-nav-text">
        <span className="me-4 hover" onClick={() => setTab(1)}>
          Unsold NFTs
        </span>
        <span className="me-4 hover" onClick={() => setTab(2)}>
          Sold NFTs
        </span>
      </div>
      <div className="admin-live-nav-bar">
        {tab === 1 ? (
          <div className="admin-bar-active"></div>
        ) : (
          <div className="admin-bar-active-past"></div>
        )}
      </div>
      {tab === 1 ? (
        nfts?.length ? (
          <div className="tab-content">
            <div className="tab-pane active">
              <div className="row">
                {/* {nftsUnsold?.map((nft, i) => {
                  return <NftCard gameId={id} nft={nft} key={i} />
                })} */}
                {showNfts(nfts)}
              </div>
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        ) : (
          <div className="mt-131 sold-out-text">
            All NFTs have been sold out!
          </div>
        )
      ) : (
        <div className="tab-content">
          <div className="tab-pane active">
            <div className="row">
              {/* {nftsSold?.map((nft, i) => {
                return <NftCard gameId={id} nft={nft} key={id} />
              })} */}
              {showNfts(nfts)}
              {showNfts(nfts).length > 0 ? (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function NftCard({ game, nft, total }) {
  let navigate = useNavigate();
  const nftDetails = () => {
    navigate(`/admin/live-games/${game.id}/${nft.tokenId}`, {
      state: {
        game,
        nft,
        total,
      },
    });
  };

  return (
    <div
      id={nft.tokenId}
      onClick={() => nftDetails(total)}
      className="details-card m-2 cursor-pointer"
    >
      {/* <div className="details-title">{total}</div> */}
      <div className="details-title">{nft.tokenId}</div>
      <span className="price-tag price-title-text">PRICE:</span>
      <span className="price-tag price-tag-text">
        <i className="fab fa-ethereum me-1"></i>
        {toEther(game.gamePrice)}
      </span>
    </div>
  );
}

export default AdminGameDetail;

import axios from "axios";
// import { checkPassPhrase } from "../utilities/Constants/Constant";
// import { cleanObject } from "helper/cleanObject";

const gameServiceV2 = {
  // async list(query) {
  //   if (query?.status) {
  //     try {
  //       const result = await axios.get("/games", { params: query });

  //       return result;
  //     } catch (error) {
  //       return error;
  //     }
  //   } else {
  //     const result = await axios.get("/games");
  //     return result;
  //   }
  // },
  // async listAdmin() {
  //   const result = await axios.get("/games/admin");
  //   return result;
  // },
  async create(gameId, passPhrase, words) {
    return await axios.post("/game", { gameId, passPhrase, words });
  },

  async finishedGames() {
    return await axios.get("/game");
  },

  async gameStats(gameId) {
    return await axios.get(`/game/stats/${gameId}`);
  },
  // async remove(id) {
  //   return await axios.delete("/games/" + id);
  // },
  // async update(id, payload) {
  //   return await axios.put("/games/" + id, payload);
  // },
  // async view(id) {
  //   return await axios.get("/games/" + id);
  // },
  // async checkPassPhrase(id, passPhrase) {
  //   return await axios.post("/games/checkPassPhrase/" + id, passPhrase);
  // },
  // async setTokenAddress(id, payload) {
  //   // console.log('setTOken')
  //   return axios.post(`/games/${id}/setToken`, payload);
  // },
  // async exchangeEthToUSD() {
  //   return await axios.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
  // },
  // async getByAccount(account) {
  //   return await axios.get("/games/account/"+account);
  // },
  // async getMetaData(id,tokenID) {
  //   return await axios.get(`games/${id}/${tokenID}`);
  // },
  // async getImage(id,tokenID) {
  //   return await axios.get(`/games/image/${id}/${tokenID}`);
  // },
};

export default gameServiceV2;

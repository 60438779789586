import "../style/admin/home.css";
import "../style/responsive.css";

import PublicLayout from "../components/layouts/PublicLayout";
import PRIZE_DATA from "../constants/prize";
import gameService from "../services/gameService.js";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

function WhitePaper(props) {
  const { setAccount, setBalance } = props;
  const { account } = useWeb3React();

  const [USD, setUSD] = useState(null);

  useEffect(() => {
    const exchangeEthToUSD = async () => {
      const { data } = await gameService.exchangeEthToUSD();
      setUSD(data.USD);
    };

    exchangeEthToUSD();
  }, []);

  return (
    <PublicLayout
      balance={0}
      account={account}
      setAccount={setAccount}
      setBalance={setBalance}
    >
      <div className="wp-wrapper">
        <div className="d-flex flex-cow ">
          <div className="box-title">
            <span className="home-main-text ">
              WHITE
              <br />
              <span className="yellow-text">PAPER</span>
            </span>
          </div>
        </div>

        <div className="d-flex mt-192 content-1-wp">
          <div className="row container-box-wp-rp">
            <div className="col-7 instruction-white-page col-box-wp-rp">
              <span className="box-content-title">INSTRUCTION</span>
              <br />
              <div className="pt-4 text-instruction">
                Passphrase.live is an exciting new NFT-infused gaming platform
                that gives users the chance to win exciting rewards by guessing
                a 12-word seed phrase combination With simple mechanics and
                impressive rewards, the game offers a fun and repeatable
                experience, leaving players wanting more.
              </div>
              <div className="pt-4 text-instruction">
                The project is flipping the script on traditional NFT projects
                and finding new and exciting ways to implement and gamify a
                growing technology, whilst still offering impressive utility to
                those that wish to play their game.
              </div>
            </div>
            <div className="col-5 group-img col-box-wp-rp">
              <img
                src="./1-94811.png"
                className="img-in-group img-top-wp img-scale"
                alt=""
              />
              <img
                src="./bg-line-wp.png"
                className="img-in-group img-top-wp bg-line-wp"
                alt=""
              />
              {/* <img
                src="./bg-header-wp.png"
                className="img-in-group img-top-wp img-header-wp img-header-wp-large"
                alt=""
              />
              <img
                src="./bg-header-wp-small.png"
                className="img-in-group img-top-wp img-header-wp img-header-wp-small"
                alt=""
              /> */}
              <img
                src="./bg-spare-wp.png"
                className="img-in-group img-spare-wp img-header-wp-small"
                alt=""
              />
              <img
                src="./bg-line-small-wp.png"
                className="img-in-group img-top-wp bg-line-small-wp"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-192 content-2-wp-rp">
          <div className="row container-box2-wp-rp">
            <div className="col-6 group-img col-box-wp-rp">
              <img
                src="./group-42700.png"
                className="img-white-page img-left-wp"
                alt=""
              />
            </div>
            <div className="col-6 content-background-whit-page content-1-wp-rp col-box-wp-rp instruction-white-page">
              <span className="box-content-title">BACKGROUND</span>
              <br />
              <div className="pt-4 text-instruction">
                Gamified NFT projects are starting to see more of the limelight
                on the blockchain, as projects seek to improve the utility that
                is offered to the holders of their NFTs, and find new ways to
                stand out from the crowds. There is a certain appeal to offering
                an experience, whether that’s in collaboration with impressive
                artworks or not.
              </div>
              <div className="pt-4 text-instruction">
                A large number of projects see the merging of gamification and
                NFT technology as a long term one, building a gaming platform
                that uses NFTs as a membership to compete in a game that earns
                them sellable in-game rewards.
              </div>
              <div className="pt-4 text-instruction">
                Because of that, there is a great level of promise in breaking
                away from the mould, and providing users with an experience that
                differs from the rest. That change could come in the form of NFT
                usage, reward type, or artwork inclusion - Passphrase.live has
                ticked all of these boxes.
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex box-button-white-page content-2-wp-rp container-btn-wp-rp content-3-wp-rp">
          <div className="row container-box2-wp-rp ">
            <div className="col-6 col-button-wp-rp">
              <div className="box-content-title">INDUSTRY POTENTIAL</div>
            </div>
            <div className="col-6 col-button-wp-rp">
              <div className="text-instruction txt-box-button-white-page mb-txt-box-button-white-page">
                The industry potential for Passphrase.live can be segmented into
                three categories, each highlighting a feature of the game that
                gives it appeal. These are:
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex button-white-page">
          <div className="row container-btn-wp">
            <div className="col-4 col-button-wp-rp">
              <div className="btn-white-page txt-btn-white-page mb-pd-btn-wp">
                NFT projects more generally.
              </div>
            </div>
            <div className="col-4 col-button-wp-rp">
              <div className="btn-white-page txt-btn-white-page mb-pd-btn-wp">
                Changing technology.
              </div>
            </div>
            <div className="col-4 col-button-wp-rp">
              <div className="btn-white-page txt-btn-white-page mb-pd-btn-wp">
                Monetary rewards.
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex container-box-wp-rp">
          <div className="row">
            <div className="col-7 instruction-white-page col-box-wp-rp col-box-wp-long-rp">
              <span className="box-content-title">NFT PROJECTS MODE</span>
              <br />
              <span className="box-content-title">GENERALLY</span>
              <br />
              <div className="pt-4 text-instruction">
                Though still in its infancy, the NFT movement has managed to
                generate an impressive level of attention in such a short period
                of time. The rapid emergence of this promising technology has
                started to seep more deeply into our everyday lives, finding
                more and more ways to offer utility.
              </div>
              <div className="pt-4 text-instruction">
                Because of its prominence, the success for NFT projects is more
                easily found for those that pioneer new ways of engaging with
                the space. Enthusiasts and traders are always on the lookout for
                unique collections and projects, and are eager to be the first
                to get involved.
              </div>
              <div className="pt-4 text-instruction">
                Because of the mechanics involved with passphrase.live, these
                appealing factors can be consistently re-engaged, allowing
                people to keep coming back to enjoy the technology in the same
                way, with different outcomes - something that sets the project
                apart from the rest.
              </div>
            </div>

            <div className="col-5 group-img col-box-wp-rp">
              <img
                src="./mask-group.png"
                className="img-in-group img-right-wp"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="d-flex mt-192 container-box-wp-rp content-2-wp-rp">
          <div className="row container-box2-wp-rp">
            <div className="col-6 group-img col-box-wp-rp">
              <img
                src="./group-42703.png"
                className="img-white-page img-left-wp"
                alt=""
              />
            </div>
            <div className="col-6 content-background-whit-page content-1-wp-rp col-box-wp-rp instruction-white-page">
              <span className="box-content-title">CHANGING TECHNOLOGY</span>
              <br />
              <div className="pt-4 text-instruction">
                When it comes to the incorporation of NFT technology, a large
                portion of projects are very similar. There is an impressive
                volume of generative art projects on the market right now, that
                offer utility through the holding of NFTs.
              </div>
              <div className="pt-4 text-instruction">
                Now that the initial wave of NFT hysteria is starting to settle
                down, projects are looking for new ways to incorporate the
                technology into their business models, and this is starting to
                become the most common direction for those that wish to succeed.
                Riding on the coattails of successful projects is becoming a
                thing of the past, and the projects that differ are the ones
                that will survive.
              </div>
              <div className="pt-4 text-instruction">
                With passphrase.live, we’ve said goodbye to the classic use
                cases of NFTs, and instead are opting for a more short-term
                holding experience, whilst increasing the rewards that are
                available. There are no unreasonable requirements for finding
                value with this project, which sets it apart in itself.
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex container-box-wp-rp content-2-wp-rp content-3-wp-rp">
          <div className="row">
            <div className="col-7 instruction-white-page col-box-wp-rp col-box-wp-long-rp col-box2-wp-long-rp">
              <span className="box-content-title">MONETARY REWARDS</span>
              <br />
              <div className="pt-4 text-instruction ">
                Perhaps one of the most crucial factors to finding success with
                an NFT project is the rewards that are available to those that
                get involved. Offering the right utility can make or break an
                NFT project, meaning that it is an important thing to get right.
              </div>
              <div className="pt-4 text-instruction">
                Recognising this, passphrase.live has opted to offer monetary
                rewards for the completion of their game, competing various
                users against one another and giving them each a fair chance at
                victory. Games are short, and give the chance of instant
                utility.
              </div>
            </div>

            <div className="col-sm-5 col-12 group-img col-box-wp-rp">
              <img
                src="./group-42706.png"
                className="img-in-group img-right-wp"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="row table-distribution mt-192 content-2-wp-rp table-distribution-wp">
          <div className="col-12 tbl-rp-wp" id="no-more-tables">
            <table className="table table-bordered all-border tbl-wp">
              <tbody className="table-border table-text tb-body-white-page">
                <tr className="tb-tr-white-page">
                  <td className="td-title td-title-top fw-bolder">Rank</td>
                  <td className="td-w">1st</td>
                  <td className="td-w">2nd</td>
                  <td className="td-w">3rd</td>
                  <td className="td-w">4 - 99</td>
                  <td className="td-w">100 - 499</td>
                  <td className="td-w">500 - 999</td>
                </tr>
                <tr className="tb-tr-white-page">
                  <td className="td-title fw-bolder">Percentage</td>
                  <td className="td-w">88%</td>
                  <td className="td-w">5%</td>
                  <td className="td-w">3%</td>
                  <td className="td-w">3%</td>
                  <td className="td-w">1%</td>
                  <td className="td-w">1%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-flex mt-192 container-box-wp-rp container-box2-wp-rp">
          <div className="row">
            <div className="col-6 group-img col-box-wp-rp">
              <img
                src="./group-42708.png"
                className="img-white-page img-left-wp"
                alt=""
              />
            </div>
            <div className="col-6 content-background-whit-page content-background-whit-page-game content-1-wp-rp col-box-wp-rp instruction-white-page">
              <span className="box-content-title">GAME MECHANICS AND</span>
              <br />
              <span className="box-content-title">FORMATS</span>
              <br />
              <div className="pt-4 text-instruction">
                The game mechanics for passphrase.live are extremely simple and
                easy to understand.
              </div>
              <div className="pt-4 text-instruction">
                They were designed in this way, to ensure the most enjoyable
                experience when playing the game.
              </div>
              <div className="pt-4 text-instruction">
                The premise of the game is for users to guess a 12-word seed
                phrase out of a total of 50 available words. There are multiple
                available formats for the game, offering players different
                reward options. Of course, more players mean higher rewards -
                but a lot more competition. The different game formats can be
                seen below:
              </div>
              <div className="pt-4 text-instruction">
                The price to play a game of passphrase.live varies, and buyers
                will receive an NFT that displays a single digit. Once all
                tickets have been purchased, the game will start with in 24
                hours, with first to guess the seed phrase, and win the prize
                pool.
              </div>
            </div>
          </div>
        </div>

        <div className="row box-table table-white-page-rp content-2-wp-rp content-3-wp-rp">
          <div className="tb-rp">
            <table className="table table-text tb-w">
              <thead className="table-text-head table-head ">
                <tr>
                  <th scope="col" className="th-bg th-bg-text-rp th-wp">
                    <img
                      src="./table-head.png"
                      className="bg-table-head-wp-rp bg-table-head-wp"
                      alt=""
                    />
                    Players
                  </th>
                  <th scope="col" className="th-bg-text-rp th-wp">
                    Price ETH
                  </th>
                  <th scope="col" className="th-bg-text-rp th-wp">
                    Total ETH
                  </th>
                  <th scope="col" className="th-bg-text-rp th-wp">
                    Prize Pool ETH
                  </th>
                  <th scope="col" className="th-bg-text-rp th-wp">
                    Prize Pool USD
                  </th>
                </tr>
              </thead>
              <tbody className="table-border">
                {PRIZE_DATA.map((data, index) => {
                  return (
                    <tr key={`players-${data.players}`}>
                      <td>{data.players.toLocaleString()}</td>
                      <td>{data.priceEth}</td>
                      <td>{data.totalEth.toLocaleString()}</td>
                      <td>
                        {data.prizePoolEth.toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                      </td>
                      <td>
                        ${" "}
                        {USD
                          ? (data.prizePoolEth * USD).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "..."}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}

export default WhitePaper;

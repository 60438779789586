import "../style/admin/home.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import PRIZE_DATA from "../constants/prize";
import gameService from "../services/gameService.js";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

function HowToPlay(props) {
  const { setAccount, setBalance } = props;
  const { account } = useWeb3React();
  const [USD, setUSD] = useState(null);

  useEffect(() => {
    const exchangeEthToUSD = async () => {
      const { data } = await gameService.exchangeEthToUSD();
      setUSD(data.USD);
    };

    exchangeEthToUSD();
  }, []);

  return (
    <div className="container-how-to-play container-road-map">
      <Header
        balance={0}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      <div className="d-flex title-main">
        <div className="box-title box-main-title-rp">
          <span className="home-main-text ">
            HOW <br />
            <span className="yellow-text">TO PLAY</span>
          </span>
        </div>
        <div className="box-title">
          <div className="text-desc">
            Playing Passphrase couldn’t be simpler, but we’ve created a
            step-by-step guide to ensure that you don’t miss a trick on your
            first time. This guide will help you to hit the ground running, and
            charge full-steam-ahead towards potential profits.
          </div>
        </div>
      </div>

      <div className="row mt-192">
        <div className="col-12 box-step">
          <img
            src="./rectangle.png"
            className="w-100 border-rp-large rectangle-large"
            alt=""
          />
          <img
            src="./step.png"
            className="step-border-1 border-rp-large"
            alt=""
          />
          <img
            src="./rectangle-1-rp.png"
            className="w-100 border-rp-small"
            alt=""
          />
          <img
            src="./step-small.png"
            className="step-border-1 border-rp-small"
            alt=""
          />
          <div className="text-step-1 text-step">
            <div>STEP 1</div>
          </div>
          <div className="box-content d-flex flex-row">
            <div className="box-content-item">
              <div className="w-50 box-width-content">
                <span className="box-content-title">CHOOSE </span>
                <br className="hidden" />
                <span className="box-content-title">YOUR GAME</span>
                <div className="pt-4 box-content-text">
                  The Passphrase game is dependent on the number of players
                  involved, which subsequently alters the prize pool. Choosing
                  you game means choosing the number of people you’re up
                  against, which you can see the difference in rewards with
                  below:
                </div>
              </div>
            </div>
            <div className="box-content-item box-img-step1">
              <img
                src="./step1-how-to-play.png"
                className="img-how-to-play"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row box-table table-white-page mt-192">
        <div className="col tb-rp">
          <table className="table table-text table-bordered tb-w">
            <thead className="table-text-head table-head ">
              <tr>
                <th scope="col" className="th-bg th-bg-text-rp th-htl">
                  <img
                    src="./table-head.png"
                    className="bg-table-head"
                    alt=""
                  />
                  Players
                </th>
                <th scope="col" className="th-bg-text-rp th-htl">
                  Price ETH
                </th>
                <th scope="col" className="th-bg-text-rp th-htl">
                  Prize Pool ETH
                </th>
                <th scope="col" className="th-bg-text-rp th-htl">
                  Prize Pool USD
                </th>
              </tr>
            </thead>
            <tbody className="table-border">
              {PRIZE_DATA.map((data, index) => {
                return (
                  <tr key={`players-${data.players}`}>
                    <td className={index > 0 ? "font-size-rank-htl" : ""}>
                      {data.players.toLocaleString()}
                    </td>
                    <td>{data.priceEth}</td>
                    <td>
                      {data.prizePoolEth.toLocaleString(undefined, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}
                    </td>
                    <td>
                      ${" "}
                      {USD
                        ? (data.prizePoolEth * USD).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "..."}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mt-192">
        <div className="col-12 box-step">
          <img
            src="./rectangle-step2.png"
            className="w-100 border-rp-large rectangle-large"
            alt=""
          />
          <img
            src="./step.png"
            className="step-border-1 border-rp-large step-border-2-rp"
            alt=""
          />
          <img
            src="./rectangle-2-rp.png"
            className="w-100 border-rp-small"
            alt=""
          />
          <img
            src="./step-small.png"
            className="step-border-1 border-rp-small"
            alt=""
          />
          <div className="text-step-1 text-step">
            <div>STEP 2</div>
          </div>
          <div className="box-content d-flex flex-row">
            <div className="box-content-item">
              <div className="w-50 box-width-content box-width-content-step2">
                <span className="box-content-title">MINT </span>
                <br className="hidden" />
                <span className="box-content-title">YOUR NFT</span>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  Because the games can take place with up to 1,000,000 players,
                  signing up means minting an NFT for the entry price and
                  securing your place in the game. Upon doing so, you will
                  select a username and enter your email address.
                </div>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  Now it’s time to wait for the game to sell out, and start
                  thinking about your tactics.
                </div>
              </div>
            </div>
            <div className="box-content-item .box-img-step2">
              <img
                src="./step2-how-to-play.png"
                className="img-how-to-play"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-192">
        <div className="col-12 box-step">
          <img
            src="./rectangle-step3.png"
            className="w-100 border-rp-large rectangle-large"
            alt=""
          />
          <img
            src="./step.png"
            className="step-border-3 border-rp-large"
            alt=""
          />
          <img
            src="./rectangle-2-rp.png"
            className="w-100 border-rp-small"
            alt=""
          />
          <img
            src="./step-small.png"
            className="step-border-1 border-rp-small"
            alt=""
          />
          <div className="text-step-3 text-step">
            <div>STEP 3</div>
          </div>
          <div className="box-content box-content-step3 d-flex flex-row">
            <div className="box-content-item">
              <div className="w-50 box-width-content box-width-content-step3">
                <span className="box-content-title">SHARE WITH </span>
                <br className="hidden" />
                <span className="box-content-title">FRIENDS</span>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  As you’re waiting, you can share the game link with your
                  friends, who can track the game sellout or even join in with
                  you. The quicker the game fills up, the sooner you’ll get to
                  test your ability to guess the passphrase.
                </div>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  So jump on Twitter and Discord to share the word.
                </div>
              </div>
            </div>
            <div className="box-content-item box-img-step3">
              <img
                src="./step3-how-to-play.png"
                className="img-how-to-play"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-192">
        <div className="col-12 box-step">
          <img
            src="./rectangle-step-4.png"
            className="w-100 border-rp-large rectangle-large"
            alt=""
          />
          <img
            src="./step.png"
            className="step-border-4 border-rp-large"
            alt=""
          />
          <img
            src="./rectangle-2-rp.png"
            className="w-100 border-rp-small"
            alt=""
          />
          <img
            src="./step-small.png"
            className="step-border-1 border-rp-small"
            alt=""
          />
          <div className="text-step-4 text-step">
            <div>STEP 4</div>
          </div>
          <div className="box-content box-content-4 d-flex flex-row">
            <div className="box-content-item">
              <div className="w-50 box-width-content box-width-content-step4">
                <span className="box-content-title">GAME </span>
                <br className="hidden" />
                <span className="box-content-title">SELLOUT</span>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  Once each of the NFTs have sold for your chosen game, you’ll
                  receive an email stating that the game will start in 24 hours
                  - and the countdown will start.
                </div>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  Now you’ve got time to clear your schedule, get some practice
                  in, and develop your technique. You’ll need all the help you
                  can get!
                </div>
              </div>
            </div>
            <div className="box-content-item box-img-step4">
              <img
                src="./step4-how-to-play.png"
                className="img-how-to-play"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-192">
        <div className="col-12 box-step">
          <img
            src="./rectangle-step-5.png"
            className="w-100 border-rp-large rectangle-large"
            alt=""
          />
          <img
            src="./step.png"
            className="step-border-5 border-rp-large"
            alt=""
          />
          <img
            src="./rectangle-2-rp.png"
            className="w-100 border-rp-small"
            alt=""
          />
          <img
            src="./step-small.png"
            className="step-border-1 border-rp-small"
            alt=""
          />
          <div className="text-step-5 text-step">
            <div>STEP 5</div>
          </div>
          <div className="box-content box-content-5 d-flex flex-row">
            <div className="box-content-item">
              <div className="w-50 box-width-content box-width-content-step5">
                <span className="box-content-title">PERFECT </span>
                <br className="hidden" />
                <span className="box-content-title">YOUR GAME</span>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  Once the game starts it’s every degen for themselves.
                </div>
                <div className="pt-4 box-content-text box-content-text-step-2">
                  You’ll have to work fast and develop a solid strategy in order
                  to beat the people that you’re playing against.ƒ Prize pool
                  will vary depending on the number of players involved, but
                  distribution will always look the same, as shown below.
                </div>
              </div>
            </div>
            <div className="box-content-item box-img-step5">
              <img
                src="./step5-how-to-play.png"
                className="img-how-to-play"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row table-distribution table-distribution-how-to-play">
        <div className="col-12 brd tbl-rp-htl-border" id="no-more-tables">
          <table className="table table-bordered all-border table-border brd tbl-rp-htl">
            <tbody className="table-border table-text tb-body-white-page table-text-htp">
              <tr className="tb-tr-white-page">
                <td className="td-title td-title-top fw-bolder">
                  Distribution
                </td>
                <td className="td-w-htp">1st</td>
                <td className="td-w-htp">2nd</td>
                <td className="td-w-htp">3rd</td>
                <td className="td-w-htp">4 - 99</td>
                <td className="td-w-htp">100 - 499</td>
                <td className="td-w-htp">500 - 999</td>
                <td className="td-w-htp">1000 - 1999</td>
                <td className="td-w-htp">2000 - 3999</td>
              </tr>
              <tr className="tb-tr-white-page">
                <td className="td-title fw-bolder">%</td>
                <td className="td-w-htp">63%</td>
                <td className="td-w-htp">13%</td>
                <td className="td-w-htp">6%</td>
                <td className="td-w-htp">6%</td>
                <td className="td-w-htp">5%</td>
                <td className="td-w-htp">4%</td>
                <td className="td-w-htp">3%</td>
                <td className="td-w-htp">1%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row table-distribution">
        <div className="col">
          <div className="d-flex flex-column">
            <div className="w-50 bottom-text-htp">
              And that’s it - you’re ready to play. Rinse and repeat as many
              times as you wish, and enjoy!
            </div>
            <div className="w-50 display-text"></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HowToPlay;

import React, { useEffect, useState } from "react";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import rectangle from "../../assets/icons/rectangle.png";
import "../../style/app/instructions.css";
import "../../style/responsive.css";

import GameLayout from "../../components/layouts/GameLayout";
import { useGame } from "../../hooks/useGame";
import { useWeb3React } from "@web3-react/core";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { Divider } from "@mui/material";
import { useSubgraph } from "../../hooks/useSubgraph";

const Instructions = (props) => {
  const { gameId } = useParams();
  const { state } = useLocation();
  const [user, setUser] = useState(state?.user || {});
  const { setAccount, setBalance } = props;
  const { account } = useWeb3React();
  const { fetchGameInfo } = useSubgraph();
  // const [user] = useState("");

  const handlerUserInfo = async () => {
    // console.log("start", account, gameId, user);
    if (account && gameId && Object.keys(user).length == 0) {
      // console.log("in");
      const userInfo = await fetchGameInfo(account.toLowerCase(), gameId);

      if (userInfo?.users?.length == 0) return;

      const user = {
        ...userInfo?.users[0],
        game: userInfo,
      };

      // console.log("userInfo =>", user);

      setUser(user);
    }
  };

  useEffect(() => {
    handlerUserInfo();
  }, [user, account]);

  return (
    <GameLayout
      balance={0}
      account={account}
      setAccount={setAccount}
      setBalance={setBalance}
      user1={""}
      gameId={gameId}
    >
      <InstructionContainer {...props} user={user} />
    </GameLayout>
  );
};

const InstructionContainer = ({ user }) => {
  // console.log("instruction user =>", user);

  const game = user?.game;
  let navigate = useNavigate();
  const { getGameStatusInfo } = useGame();

  const [total, setTotal] = useState(12);
  const [filled, setFilled] = useState(0);
  const { gameId } = useParams();
  const { _sm } = useBreakpoints();

  const [hour, setHour] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);

  const [gameStatus, setGameStatus] = useState({
    title: "",
    isStarted: false,
    canPlay: false,
    diff: 0,
  });
  // const [game, setGame] = useState();

  // const [user, setUser] = useState("");

  // async function fetchGame() {
  //   try {

  //     const { data } = await gameService.view(gameId);
  //     if (data) {
  //       setGame(data.result);
  //       if (await isEndGame(data.result.tokenAddress)) {
  //         navigate("/app/end-game/" + gameId);
  //       }
  //       setTotal(data.result.numberPlayer);
  //       // setFilled(data.result.numberPlayer)
  //       setFilled(data?.result.sold);
  //       // setNftsFiltered(nftList);
  //     }
  //   } catch (error) {
  //     // console.log(error)
  //   }
  // }

  const updateGameStatus = async () => {
    const [isStarted, canPlay, _min, diff] = await getGameStatusInfo(game?.id);

    const title = canPlay
      ? `Game has been Started`
      : `Starts in ${~~_min} Minutes`;

    setGameStatus({
      canPlay,
      isStarted,
      title: isStarted ? title : "Game isn't started yet",
      diff,
    });
  };

  useEffect(() => {
    // fetchGame();
  }, [gameId]);

  // console.log('account', account)

  let [timeleft, setTimeleft] = React.useState(86400); //86400 sec
  // const timeinterval = 500; //500 sec for test, real: 1 sec
  const timeinterval = 1;

  // useEffect(() => {
  // }, [game])

  useEffect(() => {
    if (game) {
      updateGameStatus();
    }

    // if (game?.totalPlayers === game?.noOfUsers) {
    // if (game.timeStart === undefined || !game.timeStart) { // if filled is diffeent with sold number in db

    // }

    // const timeStart = new Date().getTime();
    //   //update sold number, timestart
    //   const location = window.location.href
    //   const path =
    //     location.split('app')[0] + 'app/instructions/' + gameId.split('&')[0]
    //   const payLoad = {
    //     sold: game.numberPlayer,
    //     path,
    //   }
    //   gameService.update(game._id, payLoad);

    //   setTimeleft(game.timeStart);
    //   const date = Date.now();
    //   if (date > Number(game.timestamp) * 1000 + 24 * 3600 * 1000) {
    //     timeleft = 0;
    //   } else {
    //     timeleft = Math.floor((date - Number(game.timestamp) * 1000) / 1000);
    //   }
    // } else {
    //   setTimeleft(-1000);
    // }
  }, [game]);

  useEffect(() => {
    let inter = undefined;

    if (
      game?.totalPlayers === game?.noOfUsers &&
      !gameStatus.canPlay &&
      gameStatus.isStarted
    ) {
      let left = gameStatus.diff;
      inter = setInterval(() => {
        left = left - 1 * 1000;

        let hour = Math.floor(left / (60 * 60 * 1000));
        let min = Math.floor((left % (1000 * 60 * 60)) / (1000 * 60));
        let sec = Math.floor(((left % (1000 * 60 * 60)) % (1000 * 60)) / 1000);
        setTimeleft(() => left);
        setHour((prev) => (hour < 0 ? 0 : hour));
        setMinutes((prev) => (min < 0 ? 0 : min));
        setSeconds((prev) => (sec < 0 ? 0 : sec));

        if (left <= 0) {
          setGameStatus({
            ...gameStatus,
            canPlay: true,
            diff: 0,
          });
          clearInterval(inter);
        }
      }, 1000);
    }

    return () => {
      if (inter) clearInterval(inter);
    };
  }, [user, game, gameStatus]);

  const doesUserCanPlay = hour <= 0 && minutes <= 0 && seconds <= 0;

  return (
    <div
      className="mtb"
      id="Instructions"
      style={
        _sm
          ? {
              display: "flex",
              flexDirection: "column-reverse",
              marginTop: 0,
            }
          : {}
      }
    >
      <Row>
        <Col
          md={12}
          lg={6}
          sm={12}
          xs={12}
          className={_sm ? "" : "mb-5"}
          style={
            _sm
              ? {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                  justifyContent: "flex-end",
                  marginTop: "4rem",
                  marginBottom: "2rem",
                }
              : {}
          }
        >
          <svg
            style={_sm ? { marginLeft: 10 } : {}}
            width="32"
            height="28"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 4H0V6H11V4Z" fill="#FFD600" />
            <path d="M11 0H0V2H11V0Z" fill="#FFD600" />
            <path d="M7 8H0V10H7V8Z" fill="#FFD600" />
            <path d="M13 7V15L19 11L13 7Z" fill="#FFD600" />
          </svg>
          <h1 className="yellow-text title aligninst">Instructions</h1>
        </Col>
        <Col md={12} lg={6} sm={12} xs={12}>
          <div
            className={`d-flex step-container ${_sm ? "" : "mb-5"}`}
            style={
              _sm
                ? {
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "row",
                    margin: "2rem 0px",
                  }
                : {}
            }
          >
            <span
              className={`steps w-25`}
              style={
                _sm
                  ? {
                      whiteSpace: "nowrap",
                      margin: 0,
                      fontSize: "1rem",
                      marginRight: 20,
                    }
                  : {}
              }
            >
              Step 1
            </span>
            <p className={`w-75 stepdesc`}>
              You have purchased the NFT to participate in the game. You can
              always log into your account with your wallet.
            </p>
          </div>
          {_sm && <Divider />}
          <div
            className={`d-flex step-container ${_sm ? "" : "mb-5"}`}
            style={
              _sm
                ? {
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "row",
                    margin: "2rem 0px",
                  }
                : {}
            }
          >
            <span
              className="steps w-25"
              style={
                _sm
                  ? {
                      whiteSpace: "nowrap",
                      margin: 0,
                      fontSize: "1rem",
                      marginRight: 20,
                    }
                  : {}
              }
            >
              Step 2
            </span>
            <p className="w-75 stepdesc">
              As soon as the rest of the players purchase the remaining NFTs in
              the collection, the countdown to join the game begins. We will
              remind you 1 hour before the game starts.
            </p>
          </div>
          {_sm && <Divider />}

          <div
            className={`d-flex step-container ${_sm ? "" : "mb-5"}`}
            style={
              _sm
                ? {
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "row",
                    margin: "2rem 0px",
                  }
                : {}
            }
          >
            <span
              className="steps w-25"
              style={
                _sm
                  ? {
                      whiteSpace: "nowrap",
                      margin: 0,
                      fontSize: "1rem",
                      marginRight: 20,
                    }
                  : {}
              }
            >
              Step 3
            </span>
            <p className="w-75 stepdesc">
              Try to guess the correct passphrase of the wallet. If you are the
              lucky winner, you'll win the prize pool.
            </p>
          </div>
        </Col>
        <img src={rectangle} className="mt-1" alt="" />
      </Row>
      <Row className="mt-5">
        <Col
          md={12}
          lg={6}
          sm={12}
          xs={12}
          className={_sm ? "" : "mb-4"}
          style={
            _sm
              ? {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                  justifyContent: "flex-end",
                }
              : {}
          }
        >
          <svg
            style={_sm ? { marginLeft: 10 } : {}}
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4 6L14 4H5V21H7V14H12.6L13 16H20V6H14.4Z"
              fill="#FFD600"
            />
          </svg>
          <h1 className="yellow-text title aligninst">Status</h1>
        </Col>
        <Col md={12} lg={6} sm={12} xs={12} className={_sm ? "mt-4" : "mt-5"}>
          {_sm && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span className="p-1">
                All slots have been filled.
                <br />
                The game starts in:{" "}
              </span>
              <div className="steps mt-3">
                {hour < 10 ? `0${hour}` : hour}:{" "}
                {minutes < 10 ? `0${minutes}` : minutes}:{" "}
                {seconds < 10 ? `0${seconds}` : seconds}
              </div>
            </div>
          )}
          <div className="d-flex">
            <ProgressBar
              now={Math.floor(
                (Number(game?.noOfUsers) * 100) / Number(game?.totalPlayers)
              )}
              className="w-100"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between slots">
            {gameStatus.isStarted ? (
              <>
                {!_sm && (
                  <div className="mt-1 w-100">
                    <div className="d-flex justify-content-between">
                      <span className="p-1">
                        All slots have been filled. The game starts in:{" "}
                      </span>
                    </div>
                    <div className="steps mt-3">
                      {hour < 10 ? `0${hour}` : hour}:{" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:{" "}
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-1 w-100">
                <div className="d-flex justify-content-between">
                  <span className="p-1">
                    {Number(game?.noOfUsers)} slots filled
                  </span>
                  <span className="p-1">
                    {Number(game?.totalPlayers) - Number(game?.noOfUsers)} slots
                    remaining
                  </span>
                </div>
                <div className="steps mt-3"></div>
              </div>
            )}
          </div>
          <div
            className={`d-flex join`}
            style={{ marginTop: _sm ? "3rem" : undefined }}
          >
            <Button
              style={_sm ? { margin: "0px auto" } : {}}
              onClick={() =>
                navigate(`/app/passphrase/${user?.gameId}`, {
                  state: {
                    user,
                  },
                })
              }
              className={`${_sm ? "" : "w-100"} join-btn ${
                gameStatus && gameStatus?.isStarted && doesUserCanPlay
                  ? "active"
                  : ""
              }`}
              disabled={
                (gameStatus && !gameStatus?.isStarted) ||
                !doesUserCanPlay ||
                (gameStatus && !gameStatus?.canPlay)
              }
              // className={`w-100 join-btn ${timeleft === 0 ? "active" : "active"}`}
              size="lg"
            >
              Join the game now
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Instructions;

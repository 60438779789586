import "../style/admin/home.css";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Footer from "../components/Footer";
import Header from "../components/Header";
// import { buyKey } from "../utilities/Web3/contract";
import gameService from "../services/gameService";
import investorService from "../services/investorService";
import keyService from "../services/keyService";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";

function MintYourKey(props) {
  const { balance, setAccount, setBalance } = props;
  const { account } = useWeb3React();
  const [maxGold] = useState(750);
  const [maxSilver] = useState(1250);
  const [maxBronze] = useState(2000);

  const { gameId } = useParams();
  const [game, setGame] = useState();
  const [gold, setGold] = useState();
  const [silver, setSilver] = useState();
  const [bronze, setBronze] = useState();
  const [countGoldKey, setCountGoldKey] = useState(0);
  const [countSilverKey, setCountSilverKey] = useState(0);
  const [countBronzeKey, setCountBronzeKey] = useState(0);
  const [reload, setReload] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    let flag = true;

    if (flag) {
      const fetchKey = async (idGame) => {
        const { data } = await keyService.getByGame(idGame);

        setGold(data.result?.[0]?.gold || 0);
        setSilver(data.result?.[0]?.silver || 0);
        setBronze(data.result?.[0]?.bronze || 0);
      };
      fetchKey(gameId);
    }
    return () => {
      flag = false;
    };
  }, [gameId, reload]);

  const fetchGame = async () => {
    try {
      const { data } = await gameService.view(gameId);
      setGame(data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Date.now() < new Date(1673780400000)) {
      navigate("/counter");
    }
  }, []);

  useEffect(() => {
    fetchGame();
  }, [gameId]);

  const handleMinusGoldKey = async () => {
    if (countGoldKey > 0) {
      setCountGoldKey(countGoldKey - 1);
    }
  };

  const handlePlusGoldKey = async () => {
    if (countGoldKey < maxGold) {
      setCountGoldKey(countGoldKey + 1);
    }
  };

  const handleMinusSilverKey = async () => {
    if (countSilverKey > 0) {
      setCountSilverKey(countSilverKey - 1);
    }
  };

  const handlePlusSilverKey = async () => {
    if (countSilverKey < maxSilver) {
      setCountSilverKey(countSilverKey + 1);
    }
  };

  const handleMinusBronzeKey = async () => {
    if (countBronzeKey > 0) {
      setCountBronzeKey(countBronzeKey - 1);
    }
  };

  const handlePlusBronzeKey = async () => {
    if (countBronzeKey < maxBronze) {
      setCountBronzeKey(countBronzeKey + 1);
    }
  };

  const validateBuyKey = (metal) => {
    if (metal === "gold" && countGoldKey === 0) {
      toast.error("Please buy with amount greater than 0!");
      return false;
    }
    if (metal === "silver" && countSilverKey === 0) {
      toast.error("Please buy with amount greater than 0!");
      return false;
    }
    if (metal === "bronze" && countBronzeKey === 0) {
      toast.error("Please buy with amount greater than 0!");
      return false;
    }
    return true;
  };

  const handleBuy = async (metal) => {
    try {
      let payload = {
        game: gameId,
        investor: account,
      };
      if (!validateBuyKey(metal)) return;
      if (metal === "gold") {
        // await buyKey(
        //   game?.tokenAddress,
        //   account,
        //   metal,
        //   countGoldKey,
        //   1 * countGoldKey
        // );
        payload = { ...payload, gold: countGoldKey };
      }
      if (metal === "silver") {
        // await buyKey(
        //   game?.tokenAddress,
        //   account,
        //   metal,
        //   countSilverKey,
        //   0.5 * countSilverKey
        // );
        payload = { ...payload, silver: countSilverKey };
      }
      if (metal === "bronze") {
        // await buyKey(
        //   game?.tokenAddress,
        //   account,
        //   metal,
        //   countBronzeKey,
        //   0.25 * countBronzeKey
        // );
        payload = { ...payload, bronze: countBronzeKey };
      }
      await investorService.create(payload);

      if (metal === "gold") {
        setCountGoldKey(0);
      }
      if (metal === "silver") {
        setCountSilverKey(0);
      }
      if (metal === "bronze") {
        setCountBronzeKey(0);
      }
      setReload((prev) => prev + 1);
      toast.success("Buy success");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container-road-map">
      <Header
        balance={balance}
        account={account}
        setAccount={setAccount}
        setBalance={setBalance}
      />
      <div className="d-flex flex-cow container-title-myk">
        <div className="box-title">
          <span className="home-main-text ">
            MINT YOUR
            <br />
            <span className="yellow-text text-uppercase">founder`s key</span>
          </span>
        </div>
      </div>
      <div className="d-flex mt-192 mint-key-box">
        <div className="row w-100 container-key">
          <div className="col-4 d-flex justify-content-start container-key-gold">
            <div className="key-box">
              <div className="key-text key-text-gold text-title-gold">GOLD</div>
              <div className="border-key">
                <img src="/key-gold.png" className="key-gold-mint" alt="" />
                <img src="/group-line.png" alt="" />
                <div className="title-key-box">
                  <div className="row row-title-key">
                    <div className="col-6 text-title-key line-key">
                      <div className="txt-road-map">reward</div>
                      <div className="number-counter supply-key key-text-gold">
                        5<span className="x">x</span>
                      </div>
                    </div>
                    <div className="col-6 text-title-key">
                      <div className="txt-road-map">supply</div>
                      <div className="number-counter supply-key key-text-gold">
                        {gold ?? maxGold}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3 ml-5">
                <div className="col-6 container-counter-key">
                  <div className="counter-key">
                    <span className="pointer" onClick={handleMinusGoldKey}>
                      <img src="/minus.png" alt="" />
                    </span>
                    <span className="number-counter key-text-gold">
                      {countGoldKey}
                    </span>
                    <span className="pointer" onClick={handlePlusGoldKey}>
                      <img src="/plus.png" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="btn-buy-key container-btn-buy-key"
                    onClick={() => {
                      handleBuy("gold");
                    }}
                  >
                    Buy key
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-center container-key-silver">
            <div className="key-box">
              <div className="key-text key-text-silver">SILVER</div>
              <div className="border-key">
                <img src="/key-silver.png" className="key-gold-mint" alt="" />
                <img src="/group-line.png" alt="" />
                <div className="title-key-box">
                  <div className="row row-title-key">
                    <div className="col-6 text-title-key line-key">
                      <div className="txt-road-map">reward</div>
                      <div className="number-counter supply-key key-text-silver">
                        2.3<span className="x">x</span>
                      </div>
                    </div>
                    <div className="col-6 text-title-key">
                      <div className="txt-road-map">supply</div>
                      <div className="number-counter supply-key key-text-silver">
                        {silver ?? maxSilver}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3 ml-5">
                <div className="col-6 container-counter-key">
                  <div className="counter-key">
                    <span className="pointer" onClick={handleMinusSilverKey}>
                      <img src="/minus.png" alt="" />
                    </span>
                    <span className="number-counter key-text-gold">
                      {countSilverKey}
                    </span>
                    <span className="pointer" onClick={handlePlusSilverKey}>
                      <img src="/plus.png" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="btn-buy-key container-btn-buy-key"
                    onClick={() => {
                      handleBuy("silver");
                    }}
                  >
                    Buy key
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end container-key-bronze">
            <div className="key-box">
              <div className="key-text key-text-bronze text-title-bronze">
                BRONZE
              </div>
              <div className="border-key">
                <img src="/key-bronze.png" className="key-bronze-mint" alt="" />
                <img src="/group-line.png" alt="" />
                <div className="title-key-box">
                  <div className="row row-title-key">
                    <div className="col-6 text-title-key line-key">
                      <div className="txt-road-map">reward</div>
                      <div className="number-counter supply-key key-text-bronze">
                        1<span className="x">x</span>
                      </div>
                    </div>
                    <div className="col-6 text-title-key">
                      <div className="txt-road-map">supply</div>
                      <div className="number-counter supply-key key-text-bronze">
                        {bronze ?? maxBronze}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3 ml-5">
                <div className="col-6 container-counter-key">
                  <div className="counter-key">
                    <span className="pointer" onClick={handleMinusBronzeKey}>
                      <img src="/minus.png" alt="" />
                    </span>
                    <span className="number-counter key-text-gold">
                      {countBronzeKey}
                    </span>
                    <span className="pointer" onClick={handlePlusBronzeKey}>
                      <img src="/plus.png" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="btn-buy-key container-btn-buy-key"
                    onClick={() => {
                      handleBuy("bronze");
                    }}
                  >
                    Buy key
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row mt-192">
        <div className="container-table-mint-key">
          <table className="table table-mint-key table-dark table-striped table-bordered ">
            <thead className="txt-table-head">
              <tr>
                <th scope="col" className="bg-yellow">
                  NFT Type
                </th>
                <th scope="col" className="bg-yellow">
                  NFT Volume
                </th>
                <th scope="col" className="bg-yellow">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="txt-table-body">
              <tr className="txt-table-body">
                <td className="custom-border-l">Gold</td>
                <td>{maxGold}</td>
                <td className="custom-border-r">5x</td>
              </tr>
              <tr>
                <td className="custom-border-l">Silver</td>
                <td>{maxSilver}</td>
                <td className="custom-border-r">2.3x</td>
              </tr>
              <tr>
                <td className="custom-border-l">Bronze</td>
                <td>{maxBronze}</td>
                <td className="custom-border-r">1x</td>
              </tr>
              <tr className="yellow-text">
                <td className="custom-border-l custom-border-bottom">Total</td>
                <td className="custom-border-bottom">
                  {maxGold + maxBronze + maxSilver}
                </td>
                <td className="custom-border-r custom-border-bottom"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="row box-table table-mint-your-key">
        <div className="col">
          <table className="table table-text table-text-rm">
            <thead className="table-text-head table-head table-text-head-rm">
              <tr>
                <th scope="col" className="tbl-head-myk tbl-head-myk-first">
                  <img
                    src="/table-head.png"
                    className="bg-table-head-myk"
                    alt=""
                  />
                  NFT Type
                </th>
                <th scope="col" className="tbl-head-myk">
                  NFT Volume
                </th>
                <th scope="col" className="tbl-head-myk">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="table-border">
              <tr>
                <td>Gold</td>
                <td>750</td>
                <td>5x</td>
              </tr>
              <tr>
                <td>Silver</td>
                <td>1,250</td>
                <td>2,3x</td>
              </tr>
              <tr>
                <td>Bronze</td>
                <td>2,000</td>
                <td>1x</td>
              </tr>
              <tr>
                <td className="key-text-gold">Total</td>
                <td className="key-text-gold">4,000</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MintYourKey;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Header from "../components/Header";
import "../style/purchase-successful.css";
import Footer from "../components/Footer";
import greenTick from "../assets/icons/green.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router";
// import gameService from '../services/gameService.js'
import ShareGame from "./ShareGame";
import PrivateLayout from "../components/layouts/PrivateLayout";
import { useWeb3React } from "@web3-react/core";
import playerServiceV2 from "../services/playerServiceV2";

export const Profile = (props) => {
  const { setAccount, setBalance } = props;
  const { account } = useWeb3React();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { game } = state || {};

  const inititalValue = {
    username: "",
    email: "",
  };
  const [userInfo, setUserInfo] = useState(inititalValue);
  const [sharing, openModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { username, email } = userInfo;
  const [isUserValid, setIsUserValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isUserNameValid, setIsUserNameValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setIsUserNameValid(true);
    }
    if (name === "email") {
      setIsEmailValid(true);
    }

    setUserInfo({ ...userInfo, [name]: value });
  };

  const handlerUserMetaData = async () => {
    const res = await playerServiceV2.getUserMetaData();
    const data = res.data;

    if (data && data?.status == "OK") {
      setUserInfo(data?.user);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await playerServiceV2.updateUserMetaData(
        username,
        email
      );

      if (data && data?.status === "OK") {
        toast.success("Profile has been updated.");
      } else if (data && data?.status) {
        toast.error(data?.message);
      }
    } catch {}
    setLoading(false);
  };

  const handleShare = () => {
    openModal(!sharing);
  };

  useEffect(() => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    // if (value.match(mailformat)) {
    if (username.trim().length > 0 && email.match(mailformat)) {
      // }
      // if (isEmailValid === true && isUserNameValid === true) {
      setIsUserValid(true);
    } else {
      setIsUserValid(false);
    }
  }, [username, email]);

  // useEffect(() => {
  //   const fetchPlayers = async () => {
  //     const { data } = await playerService.list({ game: id.split("&")[0] });
  //     if (data.complete) {
  //       setPlayers(data.result);
  //     }
  //   };
  //   fetchPlayers();
  // }, [id]);

  useEffect(() => {
    handlerUserMetaData();
  }, []);

  return (
    <PrivateLayout
      balance={0}
      account={account}
      setAccount={setAccount}
      setBalance={setBalance}
      hasBack
    >
      <>
        {sharing && <ShareGame title={"Share"} onClose={handleShare} />}
        {/* <div className="card-banner">
          <div className="detail-images">
            <div className="detail-images-wpapper">
              <h1 className="yellow-text">{id}</h1>
            </div>
          </div>
        </div> */}
        <div className="from-wrapper">
          <h2>Profile</h2>
          <div className="main-form">
            <p>
              The provided email and username will be utilized to inform you
              whenever purchase a game or when the game is about to commence.
            </p>
            <div className="main_container">
              <div className="ready__started-box">
                <form className="main__form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => handleChange(e)}
                    />

                    <label htmlFor="Username">Username</label>
                    {isUserNameValid && (
                      <img src={greenTick} className="green-tick" alt="" />
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="Email">Email</label>
                    {isEmailValid && (
                      <img src={greenTick} className="green-tick" alt="" />
                    )}{" "}
                  </div>
                  <div className="text-center form-group">
                    <button
                      disabled={!isUserValid || loading}
                      className="btn btn-get"
                      onClick={handleSubmit}
                    >
                      {loading && (
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      )}
                      <span style={loading ? { marginLeft: 5 } : {}}>
                        Update
                      </span>
                    </button>
                  </div>
                </form>
                {/* <div className="text-center">
                  <button className="btn btn-get" onClick={handleShare}>
                    <span>Tweet</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    </PrivateLayout>
  );
};

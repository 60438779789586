import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Confetti from "./Confetti";
import BackArrow from "../../components/BackArrow";
import gameService from "../../services/gameService";
import { useWeb3React } from "@web3-react/core";
import { toEther } from "../../utils/helpers";
import wordServiceV2 from "../../services/wordServiceV2";
import playerServiceV2 from "../../services/playerServiceV2";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { WordDialog } from "../../components/WordDialog";

import "../../style/app/passphrase.css";

function Passphrase(props) {
  let navigate = useNavigate();
  const { account } = useWeb3React();
  const { _sm } = useBreakpoints();
  const { gameId } = useParams();
  const { state } = useLocation();
  const { user } = state || {};
  const { game } = user || {};

  if (!user || !game) {
    navigate("/app/game");
  }

  const [wordDialog, setWordDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkPhrase, setCheckPhrase] = useState(true);
  const [wrongPhrase, setWrongPhrase] = useState(false);
  const [truePhraseB, setTruePhraseB] = useState(false);
  const [gameClosed, setGameClosed] = useState(false);

  const [timerCoolDown, setTimerCoolDown] = useState(0);
  const [toggleTimer, setToggleTimer] = useState(false);

  // const [game, setGame] = useState();
  const [USD, setUSD] = useState();
  const [phraseList, setPhraseList] = useState([]);
  const [activeWords, setActiveWords] = useState([]);
  const [recentGuess, setRecentGuess] = useState([]);
  const [intel, setIntel] = useState([]);

  // const [activeKeys, setActiveKeys] = useState([]);
  useEffect(() => {
    if (document.querySelector(".container-main")) {
      document
        .querySelector(".container-main")
        .classList.remove("container-main");
    }
  }, []);

  const winPassPhrase = async () => {
    // const correctPassPhrase = activeKeys.toString().replaceAll(",", " ");
    // await answer(game.tokenAddress, correctPassPhrase, player.tokenId);
    // await playerService.updatePlayer(player._id, { isCompleted: true });
    // await getBalance();
    // navigate("/app/end-game/" + gameId);
  };

  const fetchGameWords = async () => {
    const { data } = await wordServiceV2.list(game?.id);
    if (data) {
      setPhraseList(data?.words);
    }
  };

  // const fetchPlayer = async () => {
  //   const { data } = await playerService.checkPlayer({
  //     game: gameId,
  //     walletAddress: account,
  //   });
  //   if (!data.result) {
  //     toast.error(
  //       "You can not play this game. Please purchase another NFT to play game"
  //     );
  //     navigate(`/app/instructions/${gameId}`);
  //   }
  //   setPlayer(data.result);
  // };
  // useEffect(() => {
  //   fetchGame();
  //   // fetchPlayer();
  // }, [gameId]);

  const checkPassPhare = async () => {
    setLoading(true);
    const { data } = await playerServiceV2.guessPassphrase(
      user?.gameId,
      activeWords.join()
    );

    if (data && data?.status === "OK") {
      setIntel(data?.user?.highlights);
      setRecentGuess([...activeWords]);
      setActiveWords([]);

      if (data?.user?.highlights.length === 0 && data?.user?.info === "END") {
        setGameClosed(true);
      } else if (
        data?.user?.highlights.length > 0 &&
        data?.user?.remainingCoolDown > 0
      ) {
        setTimerCoolDown(data?.user?.remainingCoolDown);
        setToggleTimer(!toggleTimer);
      }

      const best = Number(data?.user?.bestGuess);
      setTruePhraseB(true);
      if (best === 0) {
        document.querySelector(
          _sm ? ".mob-check-button" : ".check-button"
        ).style.color = "black";
        setCheckPhrase(true);
        setWrongPhrase(false);
        setTruePhraseB(false);
      } else if (best > 0 && best < 1) {
        document.querySelector(
          _sm ? ".mob-check-button" : ".check-button"
        ).style.color = "black";
        setWrongPhrase(true);
        setCheckPhrase(false);
        setTruePhraseB(false);
      } else if (best >= 1) {
        setGameClosed(true);
        setTruePhraseB(true);
        setWrongPhrase(false);
        setCheckPhrase(false);
      }
    }
    setLoading(false);
    return data;
  };

  const collectWinnings = async () => {
    navigate("/app/end-game/" + gameId);
  };

  useEffect(() => {
    let inter = undefined;

    if (timerCoolDown > 0) {
      const count = { count: timerCoolDown };
      inter = setInterval(() => {
        if (!count.count) {
          clearInterval(inter);
          return;
        }
        count.count -= 1;
        setTimerCoolDown((prev) => count.count);
      }, 1000);
    }

    return () => {
      if (inter) clearInterval(inter);
    };
  }, [toggleTimer]);

  useEffect(() => {
    const exchangeEthToUSD = async () => {
      const { data } = await gameService.exchangeEthToUSD();
      setUSD(data.USD);
    };

    exchangeEthToUSD();
  }, [game]);

  useEffect(() => {
    fetchGameWords();
  }, []);

  return (
    <>
      {wordDialog && (
        <WordDialog open={wordDialog} onClose={() => setWordDialog(false)} />
      )}
      {/* {_sm && (
        <div
          style={{
            fontSize: "1.2rem",
            backgroundColor: "black",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            onClick={() => {
              window.history.back();
            }}
          >
            <ArrowBackIosIcon />
            Back
          </div>
          <div>
            <InfoOutlinedIcon
              onClick={() => setWordDialog(true)}
              style={{ color: "#FFD600" }}
            />
          </div>
        </div>
      )} */}
      <div
        className="d-flex main-phrase"
        style={
          _sm
            ? {
                flexDirection: "column-reverse",
                backgroundColor: "black",
              }
            : {}
        }
      >
        <div
          style={
            _sm
              ? {
                  backgroundColor: "#0E0F11",
                  borderTopRightRadius: 20,
                  borderTopLeftRadius: 20,
                }
              : {}
          }
          className={`w-50 pl-96 ${_sm ? "" : "pt-24"} main-phrase-wm`}
        >
          {!_sm && (
            <div className="navbar-logo">
              <BackArrow />
              PASS<span className="yellow-text">PHRASE</span>
            </div>
          )}
          <div className="phrase-title" style={_sm ? { marginTop: 25 } : {}}>
            Choose your first word
          </div>
          <div
            style={
              _sm
                ? {
                    maxWidth: "100vw",
                    overflow: "scroll",
                    // marginBottom: "1rem",
                  }
                : {}
            }
          >
            {!_sm ? (
              <div className="phrases">
                {phraseList.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      if (activeWords.length < 12 || activeWords.includes(v)) {
                        if (activeWords.includes(v)) {
                          setActiveWords(
                            activeWords.filter((word) => word !== v)
                          );
                        } else {
                          setActiveWords([...activeWords, v]);
                        }
                      }
                    }}
                    className={`phrase-word ${
                      activeWords.includes(v) && "active-word-phrase"
                    }`}
                  >
                    {v}
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="phrases" style={{ flexWrap: "nowrap" }}>
                  {phraseList.slice(0, 17).map((v, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        if (
                          activeWords.length < 12 ||
                          activeWords.includes(v)
                        ) {
                          if (activeWords.includes(v)) {
                            setActiveWords(
                              activeWords.filter((word) => word !== v)
                            );
                          } else {
                            setActiveWords([...activeWords, v]);
                          }
                        }
                      }}
                      className={`phrase-word ${
                        activeWords.includes(v) && "active-word-phrase"
                      }`}
                    >
                      {v}
                    </div>
                  ))}
                </div>
                <div
                  className="phrases"
                  style={{ flexWrap: "nowrap", margin: 0 }}
                >
                  {phraseList.slice(17, 34).map((v, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        if (
                          activeWords.length < 12 ||
                          activeWords.includes(v)
                        ) {
                          if (activeWords.includes(v)) {
                            setActiveWords(
                              activeWords.filter((word) => word !== v)
                            );
                          } else {
                            setActiveWords([...activeWords, v]);
                          }
                        }
                      }}
                      className={`phrase-word ${
                        activeWords.includes(v) && "active-word-phrase"
                      }`}
                    >
                      {v}
                    </div>
                  ))}
                </div>
                <div
                  className="phrases"
                  style={{ flexWrap: "nowrap", margin: 0 }}
                >
                  {phraseList.slice(34, 50).map((v, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        if (
                          activeWords.length < 12 ||
                          activeWords.includes(v)
                        ) {
                          if (activeWords.includes(v)) {
                            setActiveWords(
                              activeWords.filter((word) => word !== v)
                            );
                          } else {
                            setActiveWords([...activeWords, v]);
                          }
                        }
                      }}
                      className={`phrase-word ${
                        activeWords.includes(v) && "active-word-phrase"
                      }`}
                    >
                      {v}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {_sm && (
              <button
                style={{ position: "static" }}
                disabled={
                  ((timerCoolDown > 0 || activeWords.length < 12) &&
                    !gameClosed) ||
                  loading
                }
                onClick={
                  truePhraseB || gameClosed ? collectWinnings : checkPassPhare
                }
                className={`mob-check-button ${
                  (activeWords.length === 12 || truePhraseB || gameClosed) &&
                  "active-button-check"
                } 
          ${truePhraseB && "phrase-found-success"} 
          ${wrongPhrase && timerCoolDown > 0 && "phrase-wrong-t"}`}
              >
                {(checkPhrase || timerCoolDown <= 0) &&
                  !gameClosed &&
                  `Check passphrase ${
                    timerCoolDown > 0 ? `(${timerCoolDown}s)` : ""
                  }`}
                {(wrongPhrase || checkPhrase) &&
                  gameClosed &&
                  "Check reward eligibility"}
                {wrongPhrase &&
                  !gameClosed &&
                  timerCoolDown > 0 &&
                  `Wrong passphrase, start again ${
                    timerCoolDown > 0 ? `(${timerCoolDown}s)` : ""
                  }`}
                {truePhraseB && "Collect winnings"}
                {/* {gameClosed && "Sign up for the next round"} */}
              </button>
            )}
          </div>
        </div>

        <div
          style={
            _sm
              ? {
                  paddingBottom: 50,
                }
              : {}
          }
          className="w-50 d-flex flex-column check-p pr-96 pt-24 main-phrase-wm"
        >
          <div className="d-flex justify-content-between align-items-center pl-96">
            <div className="font-minecraft-p">
              <i className="fab fa-ethereum yellow-color me-2"></i>
              {Math.round(
                Number(toEther(game?.gamePrice)) *
                  Number(game?.noOfUsers) *
                  0.8 *
                  10000
              ) / 10000}{" "}
              <span className="font-minecraft-p color-gray ms-2">
                {Math.round(
                  USD *
                    (Math.round(
                      Number(toEther(game?.gamePrice)) *
                        Number(game?.noOfUsers) *
                        0.8 *
                        10000
                    ) /
                      10000) *
                    1000
                ) / 1000}{" "}
                USD
              </span>
            </div>
            <div className="font-minecraft-p">
              {" "}
              {truePhraseB && !gameClosed ? (
                <div className="d-flex align-items-center">
                  <div className="circle-green"></div> WINNER
                </div>
              ) : (
                !gameClosed && (
                  <>
                    <img src="/rec.png" alt="" /> LIVE
                  </>
                )
              )}
              {gameClosed && (
                <div className="d-flex align-items-center">
                  <div className="circle-red"></div> FINISH
                </div>
              )}
            </div>
          </div>
          {truePhraseB && gameClosed && <Confetti />}
          <div className="d-flex flex-column pl-96 canvas-container">
            <div
              className={`you-won-title ${!truePhraseB && "d-none"}`}
              style={_sm ? { textAlign: "center" } : {}}
            >
              YOU WON!
            </div>
            {!truePhraseB && gameClosed && (
              <>
                <div className={`you-lost-title text-center  `}>
                  YOU LOST!
                  <br />
                  <span className="lost-emoji"> :( </span>
                </div>

                <div
                  className={`font-minecraft-p mt-53 ${gameClosed && "d-none"}`}
                >
                  {activeWords.length}/12 {truePhraseB && "CORRECT"} words
                </div>
                <div className={`font-minecraft-p mt-53  text-center`}>
                  Try again next time, FORTUNE favors the BOLD
                </div>
              </>
            )}
            <div
              style={_sm ? { marginTop: 30, minHeight: 75 } : {}}
              className={`font-minecraft-p mt-53 ${gameClosed && "d-none"}`}
            >
              <div style={{ marginBottom: 10 }} className="yellow-color">
                {activeWords.length}/12 words
              </div>
              {activeWords.length > 0
                ? activeWords.map((val, index) => (
                    <span key={index} className={`yellow-color`}>
                      {val + " "}
                    </span>
                  ))
                : "Let’s do this (Other players are on it!)"}
            </div>
            {!gameClosed && (
              <div
                style={_sm ? { paddingTop: 30, marginTop: 30 } : {}}
                className={`border-phrase font-minecraft-p ${
                  truePhraseB && "d-none"
                }`}
              >
                {recentGuess.length > 0 ? (
                  <div>
                    <div
                      style={_sm ? { marginTop: 20 } : {}}
                      className={`font-minecraft-p ${_sm ? "mt-53" : ""} z`}
                    >
                      RECENT GUESS{" "}
                    </div>
                    {recentGuess.map((val, index) => (
                      <span
                        key={index}
                        className={`yellow-color ${(() => {
                          if (intel?.length > 0) {
                            switch (intel[index]) {
                              case 0:
                                return "red-phrase";
                              case 1:
                                return "red-phrase";
                              // return "orange-phrase";
                              case 2:
                                return "green-phrase";
                            }
                          }
                        })()}`}
                      >
                        {val + " "}
                      </span>
                    ))}
                  </div>
                ) : (
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="circle-green"></div>
                        </td>
                        <td>Correct word, Correct position</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="circle-orange"></div>
                        </td>
                        <td>Correct word, Incorrect position</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="circle-red"></div>
                        </td>
                        <td>InCorrect word</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
          {!_sm && (
            <button
              disabled={
                ((timerCoolDown > 0 || activeWords.length < 12) &&
                  !gameClosed) ||
                loading
              }
              onClick={
                truePhraseB || gameClosed ? collectWinnings : checkPassPhare
              }
              className={`check-button ${
                (activeWords.length === 12 || truePhraseB || gameClosed) &&
                "active-button-check"
              } 
          ${truePhraseB && "phrase-found-success"} 
          ${wrongPhrase && timerCoolDown > 0 && "phrase-wrong-t"}`}
            >
              {(checkPhrase || timerCoolDown <= 0) &&
                !gameClosed &&
                `Check passphrase ${
                  timerCoolDown > 0 ? `(${timerCoolDown}s)` : ""
                }`}
              {(wrongPhrase || checkPhrase) &&
                gameClosed &&
                "Check reward eligibility"}
              {wrongPhrase &&
                !gameClosed &&
                timerCoolDown > 0 &&
                `Wrong passphrase, start again ${
                  timerCoolDown > 0 ? `(${timerCoolDown}s)` : ""
                }`}
              {truePhraseB && "Collect winnings"}
              {/* {gameClosed && "Sign up for the next round"} */}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Passphrase;

import {
  transactionFailed,
  transactionRejected,
  transactionSuccess,
} from "../hooks/swal2";
import { log } from "./logs";
import { getWeb3 } from "../contracts/getContracts";

export const sendTx = async (
  account,
  func,
  cb = () => {},
  value = undefined
) => {
  try {
    let txHash;
    await func
      .send({
        from: account,
        value: value ?? 0,
      })
      .on("transactionHash", (hash) => {
        txHash = hash;
      })
      .then((receipt) => {
        cb();
        transactionSuccess();
      })
      .catch((e) => {
        if (e.code === 4001) {
          transactionRejected();
        } else if (e?.message?.includes("not mined within 50 blocks")) {
          const web3 = getWeb3();
          if (web3) {
            const handle = setInterval(() => {
              web3.eth.getTransactionReceipt(txHash).then((res) => {
                if (res != null && res.blockNumber > 0) {
                  clearInterval(handle);
                  if (res.status) {
                    cb();
                    transactionSuccess();
                  } else {
                    transactionFailed();
                  }
                }
              });
            });
          }
        } else {
          transactionFailed();
        }
      });
  } catch (e) {
    log("sendTx", e);
  }
};

import React, { useEffect, useState } from "react";
import gamesbg from "../assets/only-games-bg.png";
import bronze from "../assets/card-image-bronze.png";
import silver from "../assets/card-image-silver.png";
import ethsvg from "../assets/icons/eth.svg";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PublicLayout from "../components/layouts/PublicLayout";
import Carousal from "../components/Carousal";
import { useSubgraph } from "../hooks/useSubgraph";
import gameServiceV2 from "../services/gameServiceV2";
import { useNavigate } from "react-router-dom";
import { useBreakpoints } from "../hooks/useBreakpoints";

export const LiveGames = () => {
  const navigate = useNavigate();
  const { fetchGamesByCategory } = useSubgraph();
  const [finished, setFinished] = useState({});
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState({
    lte1: [],
    gt1: [],
  });

  async function fetchGame() {
    const data = await fetchGamesByCategory();
    if (data) {
      const lte1 = data?.lte1?.filter(
        (game) => game?.noOfUsers !== game?.totalPlayers
      );
      const gt1 = data?.gt1?.filter(
        (game) => game?.noOfUsers !== game?.totalPlayers
      );

      setGames({
        lte1,
        gt1,
      });
    }
  }

  const handlerFetchGames = async () => {
    const { data } = await gameServiceV2.finishedGames();
    setFinished(data ? data?.games : {});
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchGame(), handlerFetchGames()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="mt-131 sold-out-text loading">Loading....</div>;
  }
  return (
    <PublicLayout
      balance={0}
      account={"account"}
      setAccount={() => {}}
      setBalance={() => {}}
    >
      <Banner
        title={"the PassProtectors"}
        highlightedText={"Join"}
        img={gamesbg}
      />
      <Box mt={8} />
      <Typography
        style={{
          fontFamily: "MineCraft",
          margin: "20px 0px",
        }}
        variant="h3"
      >
        GAMES ≤ 1 ETH
      </Typography>
      <Carousal
        arts={games?.lte1
          ?.map((game) =>
            game?.id in finished ? (
              <GameCard
                onClick={() => {
                  navigate(`/app/detail-page/${game?.id}`, {
                    state: {
                      game,
                    },
                  });
                }}
                amount={game?.totalGameAmount}
                label={
                  <Box>
                    {game?.noOfUsers} of{" "}
                    <span style={{ color: "#FF1F1F" }}>
                      {game?.totalPlayers} maximum
                    </span>
                  </Box>
                }
                img={bronze}
              />
            ) : null
          )
          .filter((game) => game)}
      />
      <Box mt={8} />
      <Typography
        style={{
          fontFamily: "MineCraft",
          margin: "20px 0px",
        }}
        variant="h3"
      >
        {"GAMES < 10 ETH"}
      </Typography>
      <Carousal
        arts={games?.gt1
          ?.map((game) =>
            game?.id in finished ? (
              <GameCard
                onClick={() => {
                  navigate(`/app/detail-page/${game?.id}`, {
                    state: {
                      game,
                    },
                  });
                }}
                amount={game?.totalGameAmount}
                label={
                  <Box>
                    {game?.noOfUsers} of{" "}
                    <span style={{ color: "#FF1F1F" }}>
                      {game?.totalPlayers} max
                    </span>
                  </Box>
                }
                img={silver}
              />
            ) : null
          )
          .filter((game) => game)}
      />
    </PublicLayout>
  );
};

const Banner = ({ title, highlightedText, img }) => {
  // const { _sm } = useBreakpoints();
  // const theme = useTheme();
  // console.log(theme.breakpoints.down("xs"));
  return (
    <Grid
      container
      justifyContent={"flex-end"}
      // align="end"
      style={{
        width: "100%",
        height: "60vh",

        padding: "0px 62px",
        paddingBottom: 36,

        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",

        ...(useMediaQuery("@media (min-width: 300px)")
          ? {
              padding: "0px 12px",
            }
          : {}),

        ...(useMediaQuery("@media (min-width: 700px)")
          ? {
              padding: "0px 24px",
            }
          : {}),

        ...(useMediaQuery("@media (min-width: 1000px)")
          ? {
              padding: "0px 62px",
            }
          : {}),
      }}
    >
      <Grid
        item
        alignItems="flex-end"
        // flexDirection={"row"}
        justifyContent="space-between"
        style={{
          display: "flex",
          width: "100%",

          ...(useMediaQuery("@media (min-width: 300px)")
            ? {
                flexDirection: "column",
                alignItems: "flex-start",
                padding: 20,
              }
            : {}),

          ...(useMediaQuery("@media (min-width: 1000px)")
            ? {
                flexDirection: "row",
                alignItems: "flex-end",
              }
            : {}),
        }}
      >
        <Typography
          variant="h2"
          style={{
            // color: "$gray12",
            fontFamily: "MineCraft",

            ...(useMediaQuery("@media (min-width: 300px)")
              ? {
                  fontSize: 32,
                  lineHeight: "32px",
                }
              : {}),

            ...(useMediaQuery("@media (min-width: 700px)")
              ? {
                  fontSize: 52,
                  lineHeight: "52px",
                }
              : {}),
          }}
        >
          <span style={{ color: "#FFFFFF" }}>{highlightedText}</span> {title}
        </Typography>

        {/*<nk-diamond collection-id="clpdwrzmq0001i60fj8bj0nxy">
          <nk-is-not-connected>
            <nk-connect-wallet-button>Connect Wallet</nk-connect-wallet-button>
          </nk-is-not-connected>
          <nk-is-connected>
            <nk-drop-mint-button
              success-title="Success!"
              success-message="You did it!"
            >
              Mint NFT
            </nk-drop-mint-button>
          </nk-is-connected>
        </nk-diamond>*}

        {/* <Button
          style={{
            boxShadow: "0px 0px 54.5758px rgba(255, 214, 0, 0.25)",
            backgroundColor: "#FFD600",
            padding: "8px 25px",
            borderRadius: 7,
            color: "black",
            textTransform: "none",
            fontSize: "1rem",
            letterSpacing: 0.9,
          }}
        >
          Enter now
        </Button> */}
      </Grid>
    </Grid>
  );
};

const GameCard = ({ amount, img, label, onClick = () => {} }) => {
  // const isMounted = useMounted();
  const { _sm: isMobile } = useBreakpoints();
  const [showButton, setShowButton] = useState(isMobile);

  const onMouseEnter = () => {
    if (isMobile) {
      return;
    }

    setShowButton(true);
  };

  const onMouseLeave = () => {
    if (isMobile) {
      return;
    }

    setShowButton(false);
  };

  useEffect(() => {
    setShowButton(isMobile);
  }, [isMobile]);

  return (
    <Grid
      container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      flexDirection="column"
      style={{
        backgroundColor: "gray",
        position: "relative",
        overflow: "hidden",
        border: "1px solid #1F1F1F",
        borderRadius: "6px",
        cursor: "pointer",
        maxWidth: 320,
        // "&.grid-item .collection_image": {
        //   transition: "transform .3s ease-in-out",
        // },
        "&:hover": {
          "&.grid-item .collection_image": {
            transform: "scale(1.1)",
          },
        },
      }}
    >
      <Grid
        className="grid-item"
        item
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
          height: 280,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={img}
          className="collection_image"
          style={{
            backgroundColor: "#1F1F1F",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transition: "transform .3s ease-in-out",
            transform: showButton ? "scale(1.1)" : "scale(1)",
          }}
        />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: 8,
            position: "absolute",
            paddingTop: 20,
            zIndex: 1200000000,
            width: "100%",
            top: 0,
            left: 0,
            height: "100%",
          }}
        >
          <img src={ethsvg} alt="" />
          <Typography
            variant="h2"
            style={{
              //   marginTop: 6,
              fontSize: 50,
              lineHeight: "48px",
              color: "white",
              textShadow: "0px 0px 15px rgba(255, 255, 255, 0.25)",
              fontFamily: "MineCraft",
            }}
          >
            {amount}
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          paddingTop: 32,
          background: "#1F1F1F",
          // color: "#FF1F1F",
          flexWrap: "nowrap",
          wordWrap: "normal",

          "@media (min-width: 300px)": {
            paddingBottom: 0,
          },

          "@media (min-width: 1200px)": {},
          paddingBottom: 25,
          fontSize: "1rem",
          fontWeight: 500,
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontFamily: "MineCraft",
            color: "white",
          }}
        >
          {amount} ETH
        </Typography>
        {label}

        <Grid
          container
          style={{
            "@media (min-width: 300px)": {
              width: "100%",
            },
            marginTop: isMobile ? 5 : 0,
            transition: "transform .25s ease",
            transform: showButton ? "translateY(0px)" : "translateY(100px)",
            position: isMobile ? "static" : "absolute",
            width: "100%",
            bottom: 0,
            // marginTop: 0,
            // "@media(min-width: 1200px)": {
            //   transform: showButton ? "translateY(0)" : "translateY(100%)",
            // },
          }}
        >
          <Button
            className="enter-bc"
            onClick={onClick}
            style={{
              width: "100%",
              justifyContent: "center",
              background: "#fff",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              minHeight: 50,
              color: "black",
              textTransform: "none",
              letterSpacing: 0.9,
              fontSize: "1rem",
            }}
          >
            Enter now
          </Button>
          {/* <Box mt={8} /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useMounted = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
};

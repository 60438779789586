import axios from "axios";
import { jwtManager } from "../helper/jwtManager";
import { isJsonString } from "../utils/helpers";

// Add a request interceptor
export default function configAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_API;
  axios.interceptors.request.use(
    (config) => {
      let info;
      const jsonStr = jwtManager.get();

      if (isJsonString(jsonStr)) {
        info = JSON.parse(jsonStr);
      }

      if (info && info?.date == ~~(Date.now() / (1000 * 60 * 60 * 24))) {
        config.headers["authorization"] = info?.auth;

        // console.log("auth => ", config.headers["authorization"]);
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

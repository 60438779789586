import axios from "axios";

const path = "/investor/"

const investorService = {
  async create(payload) {
    return await axios.post("/investor", payload);
  },

  async getByGameAndAddress(game, address) {
    return (await axios.get(`${path + game}/${address}`)).data;
  }
};

export default investorService;

import { useEffect, useState } from "react";
import "../../style/admin/live-games.css";
import { Link, useNavigate } from "react-router-dom";
import gameService from "../../services/gameService";
// import authService from '../../services/authService';
import AdminHeader from "../../components/AdminHeader";
import { useSubgraph } from "../../hooks/useSubgraph";
import gameServiceV2 from "../../services/gameServiceV2";
import { useGame } from "../../hooks/useGame";

function AdminLiveGames() {
  const { fetchGames } = useSubgraph();
  const { getGameStatusInfo } = useGame();

  let navigate = useNavigate();
  // const [user, setUser] = useState(null);
  const gameDeatils = (game) => {
    navigate(`/admin/live-games/${game.id}`, {
      state: {
        game,
      },
    });
  };
  const newGame = () => {
    navigate(`/admin/new-game`);
  };

  const [liveGames, setLiveGames] = useState([]);
  const [uncreatedGames, setUncreatedGames] = useState([]);
  const [games, setGames] = useState([]);
  const [finished, setFinished] = useState({});
  const [past, setPast] = useState();
  const [titles, setTitles] = useState({});

  const gameTitle = async (gameId) => {
    if (finished[gameId]) return "The game has a winner";

    const [isStarted, canPlay, _min, diff] = await getGameStatusInfo(gameId);

    if (!isStarted) return `Game isn't started yet`;

    if (!canPlay) return `Starts in ${~~_min} Minutes`;

    if (canPlay) return `Game has been started`;
  };

  const handlerFetchGames = async () => {
    const games = await fetchGames();
    const { data } = await gameServiceV2.finishedGames();
    setGames(games);
    setPast(false);
    setFinished(data ? data?.games : {});
  };

  useEffect(() => {
    handlerFetchGames();
  }, []);
  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const { data } = await authService.me();
  //     if (data) {
  //       setUser(data)
  //   }
  // }
  //   fetchUser()

  // }, [])

  useEffect(() => {
    const arrGame = [...games];
    if (past) {
      setLiveGames(arrGame.filter((game) => finished[game.id]));
    } else {
      setLiveGames(arrGame.filter((game) => !finished[game.id]));
    }

    const uncreated = [];

    arrGame.forEach((game) => {
      if (!(game?.id in finished)) {
        uncreated.push(game?.id);
      }
    });
    setUncreatedGames(uncreated);
    // console.log("uncreated =>", uncreated);

    // console.log("finished", finished);
    // setLiveGames(arrGame);
  }, [games, finished, past]);

  useEffect(async () => {
    if (liveGames && finished) {
      const titles = await Promise.all(
        liveGames.map((game) => gameTitle(game?.id))
      );
      const t = {};

      liveGames.forEach((game, idx) => {
        t[game?.id] = titles[idx];
      });

      setTitles(t);
    }
  }, [liveGames, finished]);

  return (
    <>
      {/* <div className="d-flex align-items-center justify-content-between py-4 navbar-main">
        <div className="navbar-logo">
          PASS<span className="yellow-text">PHRASE</span>
        </div>
        <div className="navbar-user">
          <i className="fas fa-user-circle user-avatar mr-5"></i>
          <span>{user?.name}</span>
        </div>
      </div> */}
      <AdminHeader />

      <div className="admin-live-nav-text">
        <span
          className="me-4 hover"
          onClick={() => {
            setPast(false);
          }}
        >
          Live games
        </span>
        <span
          className="hover"
          onClick={() => {
            setPast(true);
          }}
        >
          Past games
        </span>
      </div>
      <div className="admin-live-nav-bar">
        {!past ? (
          <div className="admin-bar-active"></div>
        ) : (
          <div className="admin-bar-active-past"></div>
        )}
      </div>

      <div className="game-container d-flex">
        {liveGames.length === 0 ? (
          <div className="admin-no-game-text">
            There are currently no games available
          </div>
        ) : (
          liveGames.map((val, index) => (
            <div className="game-card m-2">
              <div
                key={index}
                id={val.id}
                onClick={() => {
                  gameDeatils(val);
                }}
              >
                <div className="game-card-title">
                  {val.totalPlayers} <br />
                  players
                </div>
                <table className="mt-65">
                  <tbody>
                    <tr>
                      <td>
                        <img src="/game-1.png" alt="" />
                      </td>
                      <td className="game-table-text">
                        {val.noOfUsers} NFTs sold
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src="/game-2.png" alt="" />
                      </td>
                      <td className="game-table-text ps-1">
                        {titles && finished && titles[val?.id]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {finished && finished[val.id] && (
                <tr>
                  <td>
                    <img src="/game-3.png" alt="" />
                  </td>
                  <td className="game-table-text ps-1">
                    <Link to={`/app/game/results/${val.id}`}>Game Results</Link>
                  </td>
                </tr>
              )}

              {uncreatedGames.includes(val.id) && (
                <tr>
                  <td>!!</td>
                  <td className="game-table-text ps-1">Game didn't created</td>
                </tr>
              )}
            </div>
          ))
        )}
      </div>
      <div onClick={newGame} className="new-game-button mb-5">
        Start Another Game
      </div>
    </>
  );
}

export default AdminLiveGames;

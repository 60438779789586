import { toEther, toWei } from "../../utils/helpers";
import { log } from "../../utils/logs";
import { sendTx } from "../../utils/sendTx";
import { passPhraseContract } from "../getContracts";

// =========================================================================== write

export const gameOwner = async () => {
  try {
    let contract = passPhraseContract();
    return await contract?.methods.owner().call();
  } catch (e) {
    log("gameOwner", e);
    return "0x";
  }
};

export const totalGamesCreated = async () => {
  try {
    let contract = passPhraseContract();
    return await contract?.methods.totalGamesCreated().call();
  } catch (e) {
    log("createGame", e);
    return 0;
  }
};

export const gameDetails = async (gameId) => {
  try {
    let contract = passPhraseContract();
    const result = await contract?.methods.gameDetails(gameId).call();

    return {
      isAdminClaimed: result?.treasuryEarningsCollected,
      isCreatorClaimed: result?.creatorEarningsCollected,
    };
  } catch (e) {
    log("gameDetails", e);
    return {
      isAdminClaimed: false,
      isCreatorClaimed: false,
    };
  }
};

export const createGame = async (
  noOfPlayers,
  gamePrice,
  account,
  cb = () => {}
) => {
  try {
    let contract = passPhraseContract();
    await sendTx(
      account,
      contract?.methods.createGame(noOfPlayers, toWei(gamePrice)),
      cb
    );
  } catch (e) {
    log("createGame", e);
  }
};

export const withdrawCreatorEarning = async (
  gameId,
  account,
  cb = () => {}
) => {
  try {
    let contract = passPhraseContract();
    await sendTx(account, contract?.methods.withdrawCreatorEarning(gameId), cb);
  } catch (e) {
    log("withdrawCreatorEarning", e);
  }
};

export const withdrawTreasuryEarning = async (
  treasuryAddress,
  gameId,
  account,
  cb = () => {}
) => {
  try {
    let contract = passPhraseContract();
    await sendTx(
      account,
      contract?.methods.withdrawTreasuryEarning(treasuryAddress, gameId),
      cb
    );
  } catch (e) {
    log("withdrawTreasuryEarning", e);
  }
};

export const claimReward = async (
  gameId,
  rank,
  rewardAmount,
  signature,
  account,
  cb = () => {}
) => {
  try {
    let contract = passPhraseContract();
    await sendTx(
      account,
      contract?.methods.claimReward(
        gameId,
        rank,
        toWei(rewardAmount),
        signature
      ),
      cb
    );
  } catch (e) {
    log("claimReward", e);
  }
};

export const buyGame = async (gameId, price, account, cb = () => {}) => {
  try {
    let contract = passPhraseContract();
    await sendTx(account, contract?.methods.buyGame(gameId), cb, price);
  } catch (e) {
    log("buyGame", e);
  }
};
